<template>	
    <v-row  v-if="ready">
        <v-col cols="12" v-if="!theseFiles.length">
            <v-alert class="mt-2 mx-3" dense type="error" v-if="errors">
                {{ errors }}
            </v-alert>
            <p class="text-center my-3">
                <span class="subtitle-1 d-block px-3 mt-5 mt-md-7 mb-5 mb-md-7 lh-14 secondary--text text-center">Add a photo or document to help your contacts better understand your notes and instructions.</span>
                <v-btn color="primary" rounded @click.native="handleImgClick()"><v-icon small color="white" class="mr-2">fa fa-plus-circle</v-icon> Attach a Photo or PDF</v-btn>
            </p>
            <p class="text-center body-2">Images and PDFs only. Max 5MB filesize.</p>
        </v-col>
        <v-col cols="12" v-else>
            <template v-for="(file,idx) in theseFiles">
                <v-card :key="'file'+idx" class="mb-2 mt-3 mx-2 mx-md-3 elevation-2">
                    <v-row no-gutters class="align-center">
                        <v-col cols="4" md="2" lg="3" @click="preview=idx">
                            <v-img v-if="file.preview" cover class="elevation-1 hover-pointer" height="100%" :max-height="maxHeight" :min-height="maxHeight" min-width="100" :src="file.preview" />
                            <v-img v-if="file.type == 1" cover class="elevation-1 hover-pointer" height="100%" :max-height="maxHeight" :min-height="maxHeight" min-width="100" :src="file.file" />
                            <div v-if="file.type==2 || (!file.type && !file.preview)" :style="'height: ' + maxHeight + 'px;'" class="secondary text-center pt-3 py-md-5">
                                <v-icon :size="($vuetify.breakpoint.smAndDown) ? 50 : 80" color="primary" class="mt-1">fa fa-file-pdf</v-icon>
                                <span style="max-width:90%;" class="caption text-truncate mx-auto mt-2 d-block white--text">{{ file.name || file.file.name }}</span>
                            </div>
                        </v-col>
                        <v-col cols="8" md="10" lg="9">
                            <p class="body-2 pt-1 pt-md-0 px-2 px-md-3 px-lg-4 mb-0" v-if="file.caption && file.caption.length>0">{{ getCaption(file.caption) }}</p>
                            <p class="text-center body-2 lh-14 mb-0" v-else><v-btn rounded outlined @click.native="handleCaptionEdit(idx)" small color="primary"><v-icon small class="mr-2">fa fa-plus</v-icon> Add a Caption</v-btn></p>
                        </v-col>
                        <v-col cols="12">
                            <v-card-actions class="card-actions">
                                <template v-if="theseFiles.length > 1">
                                    <v-btn @click.native="sortItems(idx,idx-1)" :disabled="idx==0" icon color="primary"><v-icon>fa fa-arrow-circle-up</v-icon></v-btn>
                                    <span class="text-center caption grey--text darken-3" style="margin-top: -3px;">sort</span>
                                    <v-btn @click.native="sortItems(idx,idx+1)" :disabled="idx==theseFiles.length-1" icon color="primary"><v-icon>fa fa-arrow-circle-down</v-icon></v-btn>
                                </template>
                                <v-spacer />
                                <v-btn small text outlined v-if="file.caption && file.caption.length" @click.native="handleCaptionEdit(idx)" color="primary"><v-icon small class="mr-2">fa fa-edit</v-icon> Caption</v-btn>
                                <v-btn icon :loading="removing===file.uuid" @click.native="deleteFile=file.uuid" small color="error"><v-icon>fa fa-trash-alt</v-icon></v-btn>
                            </v-card-actions>
                        </v-col>
                    </v-row>
                </v-card>
            </template>
        </v-col>
        <v-col cols="12" v-if="theseFiles.length">
            <v-alert class="mt-2 mx-3" dense type="error" v-if="errors">
                {{ errors }}
            </v-alert>
            <p class="text-center mt-0 mb-3">
                <v-btn color="primary" :disabled="theseFiles.length>9" @click.native="handleImgClick()" rounded>
                    <template v-if="theseFiles.length < 10">
                        <v-icon small color="white" class="mr-2">fa fa-plus-circle</v-icon> Add another Attachment
                    </template>
                    <template v-else>
                        10-File Limit Reached
                    </template>
                </v-btn>
            </p>
        </v-col>
        <input
            type="file"
            style="display: none"
            ref="imageBtn"
            accept="image/*,application/pdf"
            @change="onFilePicked"
        >
        <Confirm v-if="deleteFile" title="Remove Attachment" subtitle="Are you sure you want to delete this attachment?" @closed="deleteFile=false" @confirmed="handleFileDelete(deleteFile)" />
        <MediaViewer v-if="preview!==null" @closed="preview=null" :preview="preview" :files="theseFiles" />
        <v-dialog v-if="editCaption" :value="true" overlay-opacity="0.6" overlay-color="#000" @input="editCaption=null" max-width="600px">
            <v-card>
                <v-card-title class="py-2 dcard-title">
                    <h3 class="headline mb-0 primary--text text-center">Update Caption</h3>
                    <v-btn absolute right icon @click.native="editCaption=null"><v-icon color="primary">fa fa-times-circle</v-icon></v-btn>
                </v-card-title>
                <v-card-text class="pa-0 pb-2">
                    <v-divider />
                    <v-row class="ma-3 mb-0">
                        <v-col cols="12" class="pb-0">
                            <v-textarea :autofocus="!$vuetify.breakpoint.smAndDown" counter="250" class="pb-0" rows="4" v-model="newCaption" label="Caption" maxlength="250" outlined />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="card-actions">
                    <v-btn text outlined @click.native="editCaption=null">Cancel</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" class="px-3" :loading="saving" @click.native="saveCaption()">Save Caption</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>

import { mapState, mapMutations } from 'vuex'

export default {
    name: 'UserNotesFiles',
    props: {
        item: {
            required: true,
            type: Object
        },
    },
	data: () => ({
        editCaption: null,
        editCaptionIdx: null,
        errors: null,
        newCaption: '',
        theseFiles: [],
        file: null,
        formData: null,
        uploading: false,
        deleteFile: false,
        preview: null,
        previewIdx: null,
        removing: false,
        ready: false,
        saving: false,
	}),
	components: {
		Confirm: () => import('@/components/common/Confirm'),
		MediaViewer: () => import('@/components/common/MediaViewer'),
	},
	computed: {
		...mapState(['user']),
        maxHeight(){
            if(this.$vuetify.breakpoint.smAndDown){
                return 100;
            }
            return 150;
        }
	},
	methods: {
        ...mapMutations(['setUser']),
		initData(){
            this.theseFiles = JSON.parse(JSON.stringify(this.item.files));
            this.ready = true;
            this.getTotalUserFileSize();
        },
        getCaption(caption){
            if(this.$vuetify.breakpoint.smAndDown){
                if(caption && caption.length > 100){
                    return caption.substr(0,100) + '...';
                }
            }
            return caption;
        },
        handleImgClick(){
            this.pickFile();
        },
        handleCaptionEdit(idx){
            this.newCaption = this.theseFiles[idx].caption;
            this.editCaptionIdx = idx;
            this.editCaption = true;
        },
        pickFile () {
            this.$refs.imageBtn.click()
        },
        getTotalUserFileSize(){
            let totalFileSize = 0;
            if(!this.user.notes){
                return 0;
            }
            this.user.notes.forEach(note => {
                note.files.forEach(file => {
                    totalFileSize += file.size;
                })
            })
            return totalFileSize;
        },
        onFilePicked (e) {

            this.errors = null;

            // let image;
            let file = e.target.files[0];

            let fileSize = (file && file.size) ? file.size / 1024 / 1024 : null;

            if(!fileSize || fileSize > 5){
                this.errors = "File size exceeds max of 5MB.";
                return;
            }
                        
            if((this.getTotalUserFileSize() + fileSize) > 26000000){
                this.errors = "File will exceed total 25MB file limit for notes.";
                return;
            }

            let preview = null;
            if(file.type.indexOf('image') !== -1){
                preview = URL.createObjectURL(file);
            }
            this.theseFiles.push({
                preview: preview,
                file: file,
                isNew: true,
            });

            this.$emit('updated', this.theseFiles);
            this.save();

        },
        removeFile(idx){
            this.theseFiles.splice(idx,1);
        },
        sortItems(from, to){
            
            let fromIdx = parseInt(from);
            let toIdx = parseInt(to);
            
            if(fromIdx == toIdx){
                return;
            }
            
            from = JSON.parse(JSON.stringify(this.theseFiles[fromIdx]));
            from.file = this.theseFiles[fromIdx].file;
            to = JSON.parse(JSON.stringify(this.theseFiles[toIdx]));
            to.file = this.theseFiles[toIdx].file;

            if(fromIdx > toIdx){
                this.theseFiles.splice(fromIdx, 1);
                this.theseFiles.splice(toIdx, 1, from);
                this.theseFiles.splice(toIdx+1, 0, to);
            }else{
                this.theseFiles.splice(toIdx, 1);
                this.theseFiles.splice(fromIdx, 1, to);
                this.theseFiles.splice(fromIdx+1, 0, from);
            }

            let sorts = [];
            for(let i in this.theseFiles){
                if(this.theseFiles[i].uuid){
                    sorts.push(this.theseFiles[i].uuid);
                }else{
                    sorts.push(null);
                }
            }

            this.formData = new FormData();
            this.formData.append('sorts', JSON.stringify(sorts));
            let endpoint = (this.item.uuid) ? '/' + this.item.uuid : '';
            this.saving = true;
            if(this.item.uuid){
                this.formData.append('_method', 'put');
            }
            let request = {
                endpoint: '/notes' + endpoint, 
                method: 'post',
                data: this.formData,
                apiKey: this.user.api_token || null,
                header: { 'Content-Type': 'multipart/form-data' },
            };
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 200 || res.status == 201){
                    // this.$eventHub.$emit('refreshUser');
                }else{
                    this.handleErrorResponse(res);
                }
            }).catch(()=>{
                this.errors = "Something went wrong! Please try again.";
            }).finally(()=>{
                this.saving = false;
            });
            
            this.$emit('sorted');
            this.$emit('updated', this.theseFiles);
        },
        save(){ 
            this.saving = true;
            this.errors = null;
            this.formData = new FormData();

            // handle files
            if(this.theseFiles.length){
                for(let i in this.theseFiles){
                    if(this.theseFiles[i].isNew) {
                        this.formData.append('file_' + i, this.theseFiles[i].file);
                        this.formData.append('file_' + i + '_caption', this.theseFiles[i].caption || '');
                        this.formData.append('file_' + i + '_sort', i);
                    }
                }
                if(this.filesSorted){
                    let sorts = [];
                    for(let i in this.theseFiles){
                        if(this.files[i].uuid){
                            sorts.push(this.theseFiles[i].uuid);
                        }else{
                            sorts.push(null);
                        }
                    }
                    this.formData.append('sorts', JSON.stringify(sorts));
                }
            }

            // augment request if new item vs. existing
            let endpoint = (this.item.uuid) ? '/' + this.item.uuid : '';
            if(this.item.uuid){
                this.formData.append('_method', 'put');
            }

            let request = {
                endpoint: '/notes' + endpoint, 
                method: 'post',
                data: this.formData,
                apiKey: this.user.api_token || null,
                header: { 'Content-Type': 'multipart/form-data' },
            };
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 200 || res.status == 201){
                    this.$eventHub.$emit('refreshUser');
                    this.$eventHub.$emit('snackBar', {
                        text: 'Your attachment has been saved!'
                    });
                    this.theseFiles = res.data.files;
                    this.$eventHub.$emit('refreshUser');
                    this.$emit('updated');
                    this.$emit('fileCount', this.theseFiles.length);
                }else{
                    this.handleErrorResponse(res);
                }
            }).catch(()=>{
                this.errors = "Something went wrong! Please try again.";
            }).finally(()=>{
                this.saving = false;
            });
        },
        saveCaption(){

            if(this.theseFiles[this.editCaptionIdx].isNew){
                this.theseFiles[this.editCaptionIdx].caption = this.newCaption;
                this.editCaptionIdx = null;
                this.editCaption = false;
                return;
            }

            this.saving = true;
            let request = {
                endpoint: '/notes/captions/' + this.theseFiles[this.editCaptionIdx].uuid, 
                method: 'post',
                data: {
                    'caption' : this.newCaption,
                    '_method' : 'put'
                },
                apiKey: this.user.api_token || null
            };
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 200){
                    this.theseFiles[this.editCaptionIdx].caption = this.newCaption;
                    this.$eventHub.$emit('snackBar', {
                        text: 'Your caption has been updated!'
                    });
                    this.$eventHub.$emit('refreshUser');
                }
            }).catch(()=>{
            }).finally(()=>{
                this.$eventHub.$emit('refreshUser');
                this.$emit('updated', this.theseFiles);
                this.saving = false;
                this.editCaptionIdx = null;
                this.editCaption = false;
            });
        },
        handleFileDelete(deleteFileUuid){
            this.removing = deleteFileUuid;
            let request = {
                endpoint: '/notes/files/' + deleteFileUuid, 
                method: 'post',
                data: {
                    '_method' : 'delete'
                },
                apiKey: this.user.api_token || null
            };
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 204){
                    
                    for(let i in this.theseFiles){
                        if(this.theseFiles[i].uuid == deleteFileUuid){
                            this.theseFiles.splice(i,1);
                        }
                    }
                    
                    this.$eventHub.$emit('snackBar', {
                        text: 'Your attachment has been removed.'
                    });
                    this.$emit('fileCount', this.theseFiles.length);

                    setTimeout(() => {
                        this.$eventHub.$emit('refreshUser');
                        this.$emit('updated', this.theseFiles);
                    }, 200);
                }
            }).catch(()=>{
            }).finally(()=>{
                this.deleteFile = null;
                this.removing = null;
            });
        }
	},
	mounted(){
		this.initData();
	},
    watch: {
        saving(){
            this.$emit('loading', this.saving);
        }
    }
}
</script>