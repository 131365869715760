<template>
    <v-dialog :value="true" overlay-opacity="0.6" overlay-color="#000" @input="close()" max-width="500px">
        <v-card>
            <v-card-title class="py-2 dcard-title">
                <h3 class="headline mb-0 primary--text text-center">Note Examples</h3>
                <v-btn absolute right icon @click.native="close"><v-icon color="primary">fa fa-times-circle</v-icon></v-btn>
            </v-card-title>
            <v-card-text class="pa-0 pb-2">
                <v-divider />
                <v-container pa-0 pt-4 pb-3 px-4 px-md-5>
                    <v-row>
                        <v-col cols="12" class="text-center">
                            <!-- Medical -->
                            <template v-if="field == 'medical-medications'">
                                <p class="subtitle-1 mb-0 font-weight-medium secondary--text">Your Medications</p>
                                <p class="body-2">List the names and dosages of any medications you are currently taking, including over-the-counter.</p>
                                <v-alert dense class="body-2 text-left mb-0" color="#666" outlined>
                                    <span class="caption secondary--text">Example:</span>
                                    <p class="mb-0">
                                        Singulair - 10mg - Daily<br>
                                        Lorazapam - 1mg - As Needed<br>
                                        Multivitamin - 1 pill - Daily<br>
                                        Tylenol - 2 pills - As Needed
                                    </p>
                                </v-alert>
                            </template>
                            <template v-if="field == 'medical-allergies'">
                                <p class="subtitle-1 mb-0 font-weight-medium secondary--text">Medication Allergies</p>
                                <p class="body-2">List any medications you may be allergic to.</p>
                                <v-alert dense class="body-2 text-left mb-0" color="#666" outlined>
                                    <span class="caption secondary--text">Example:</span>
                                    <p class="mb-0">
                                        Penicillin<br>
                                        Latex<br>
                                    </p>
                                </v-alert>
                            </template>
                            <template v-if="field == 'medical-conditions'">
                                <p class="subtitle-1 mb-0 font-weight-medium secondary--text">Medical Conditions</p>
                                <p class="body-2">List any medical conditions that you have been diagnosed with.</p>
                                <v-alert dense class="body-2 text-left mb-0" color="#666" outlined>
                                    <span class="caption secondary--text">Example:</span>
                                    <p class="mb-0">
                                        Irregular heart beat.<br>
                                        Colon Cancer - diagnosed at age 42. No recurrence.<br>
                                    </p>
                                </v-alert>
                            </template>
                            <template v-if="field == 'medical-other'">
                                <p class="subtitle-1 mb-0 font-weight-medium secondary--text">Other Medical Notes</p>
                                <p class="body-2">List any additional information that should be relayed to your care provider(s).</p>
                                <v-alert dense class="body-2 text-left mb-0" color="#666" outlined>
                                    <span class="caption secondary--text">Example:</span>
                                    <p class="mb-0">
                                        Have recently been experiencing intense bouts of acid reflux.<br>
                                        I have bad reactions to general anesthesia.<br>
                                    </p>
                                </v-alert>
                            </template>
                            <template v-if="field == 'medical-providers'">
                                <p class="subtitle-1 mb-0 font-weight-medium secondary--text">Medical Providers</p>
                                <p class="body-2">List your medical providers and how to contact them.</p>
                                <v-alert dense class="body-2 text-left mb-0" color="#666" outlined>
                                    <span class="caption secondary--text">Example:</span>
                                    <p class="mb-0">
                                        Dr. Womack - Primary Care.<br>
                                        Center City Medical Partners - (888) 555-5555<br>
                                    </p>
                                </v-alert>
                            </template>
                            <!-- Other Notes -->
                            <template v-if="field == 'other-title'">
                                <p class="subtitle-1 mb-0 font-weight-medium secondary--text">Note Titles</p>
                                <p class="body-2">Provide a brief description of what the notes contain.</p>
                                <v-alert dense class="body-2 text-left mb-0" color="#666" outlined>
                                    <span class="caption secondary--text">Examples:</span>
                                    <p class="mb-0">
                                        How to Contact my Parents<br>
                                        Retrieving my Important Documents<br>
                                        Contacting my Employer
                                    </p>
                                </v-alert>
                            </template>
                            <template v-if="field == 'other-content'">
                                <p class="subtitle-1 mb-0 font-weight-medium secondary--text">Note Contents</p>
                                <p class="body-2">Provide details and instructions related to your note's title.</p>
                                <v-alert dense class="body-2 text-left mb-0" color="#666" outlined>
                                    <span class="caption secondary--text">Examples:</span>
                                    <p class="mb-0">
                                        Be sure to check in with my mom and dad. Mom's number is...<br>
                                        My living-will and other documents are filed with my lawyer, contact them at...<br>
                                        Contact my manager, Garth, at (888) 555-5555...
                                    </p>
                                </v-alert>
                            </template>
                            <!-- Pets -->
                            <template v-if="field == 'pet-feeding'">
                                <p class="subtitle-1 mb-0 font-weight-medium secondary--text">Feeding Your Pet</p>
                                <p class="body-2">Provide instructions related to any treats, special diets and other feeding outside your pet's normal schedule.</p>
                                <v-alert dense class="body-2 text-left mb-0" color="#666" outlined>
                                    <span class="caption secondary--text">Example:</span>
                                    <p class="mb-0">
                                        Can have up to 4 raw-hide treats per day.<br>
                                        Enjoys when treats are put into his black rubber Kong ball.<br>
                                        Feed wet food if they become sick or stop eating.
                                    </p>
                                </v-alert>
                            </template>
                            <template v-if="field == 'pet-potty'">
                                <p class="subtitle-1 mb-0 font-weight-medium secondary--text">Your Pet's Potty Routine</p>
                                <p class="body-2">Provide details for when and where your pet does its business everyday, including any cleaning instructions for accidents.</p>
                                <v-alert dense class="body-2 text-left mb-0" color="#666" outlined>
                                    <span class="caption secondary--text">Example:</span>
                                    <p class="mb-0">
                                        Goes #1 and #2 in yard each morning.<br>
                                        Likes to usually go out 4 times daily.<br>
                                        Will go on pet-pad if he can not get outside.
                                    </p>
                                </v-alert>
                            </template>
                            <template v-if="field == 'pet-providers'">
                                <p class="subtitle-1 mb-0 font-weight-medium secondary--text">Pet Care Providers</p>
                                <p class="body-2">List names and contact details for your pet's medical care, boarding and other providers.</p>
                                <v-alert dense class="body-2 text-left mb-0" color="#666" outlined>
                                    <span class="caption secondary--text">Example:</span>
                                    <p class="mb-0">
                                        Is registered patient at Long Animal Hospital - (888) 555-5555.<br>
                                        Goes to daycare/boarding at K9 Playland - (800) 555-5555.<br>
                                    </p>
                                </v-alert>
                            </template>
                            <template v-if="field == 'pet-other'">
                                <p class="subtitle-1 mb-0 font-weight-medium secondary--text">Other Pet Information</p>
                                <p class="body-2">Provide other instructions that you wish for those caring for your pet to know.</p>
                                <v-alert dense class="body-2 text-left mb-0" color="#666" outlined>
                                    <span class="caption secondary--text">Example:</span>
                                    <p class="mb-0">
                                        He likes to sleep next to your bed.<br>
                                        Gets anxious around other dogs so keep tight hold on harness.<br>
                                        Takes heartworm medication monthly - call vet for refills.
                                    </p>
                                </v-alert>
                            </template>
                            <!-- Property -->
                            <template v-if="field == 'prop-access'">
                                <p class="subtitle-1 mb-0 font-weight-medium secondary--text">Accessing Your Property</p>
                                <p class="body-2">Provide instructions to gain entry into your property.</p>
                                <v-alert dense class="body-2 text-left mb-0" color="#666" outlined>
                                    <span class="caption secondary--text">Examples:</span>
                                    <p class="mb-0">
                                        There is a key hidden beneath rock beside front door.<br>
                                        Code for lockbox (front door) is 4459.<br>
                                        Go to front-office to ask for entry.
                                    </p>
                                </v-alert>
                            </template>
                            <template v-if="field == 'prop-entering'">
                                <p class="subtitle-1 mb-0 font-weight-medium secondary--text">Entering Your Property</p>
                                <p class="body-2">Provide details for others entering your property for the first time.</p>
                                <v-alert dense class="body-2 text-left mb-0" color="#666" outlined>
                                    <span class="caption secondary--text">Examples:</span>
                                    <p class="mb-0">
                                        Disarm alarm panel by front door by entering code *4434.<br>
                                        Be careful to not let dog out.<br>
                                        Knock first if you see car in driveway.
                                    </p>
                                </v-alert>
                            </template>
                            <template v-if="field == 'prop-items-inside'">
                                <p class="subtitle-1 mb-0 font-weight-medium secondary--text">Items Inside Your Property</p>
                                <p class="body-2">Provide instructions others may need to care for any plants, etc. inside your home.</p>
                                <v-alert dense class="body-2 text-left mb-0" color="#666" outlined>
                                    <span class="caption secondary--text">Examples:</span>
                                    <p class="mb-0">
                                        Please water each plant except for the orchids.<br>
                                        Turn off all lights before leaving.<br>
                                        Bring in any packages left outside.
                                    </p>
                                </v-alert>
                            </template>
                            <template v-if="field == 'prop-items-outside'">
                                <p class="subtitle-1 mb-0 font-weight-medium secondary--text">Items Outside Your Property</p>
                                <p class="body-2">Provide instructions others may need to care for any cars or other items located outside your property.</p>
                                <v-alert dense class="body-2 text-left mb-0" color="#666" outlined>
                                    <span class="caption secondary--text">Example:</span>
                                    <p class="mb-0">
                                        Car keys are hanging in kitchen, please ensure it's locked. <br>
                                        Bring bicycle inside. <br>
                                        Bring garbage bins to back yard if on street.
                                    </p>
                                </v-alert>
                            </template>
                            <template v-if="field == 'prop-securing'">
                                <p class="subtitle-1 mb-0 font-weight-medium secondary--text">Securing Your Property</p>
                                <p class="body-2">Provide instructions others may need to properly secure your home.</p>
                                <v-alert dense class="body-2 text-left mb-0" color="#666" outlined>
                                    <span class="caption secondary--text">Examples:</span>
                                    <p class="mb-0">
                                        Leave front-porch light on.<br>
                                        Arm home alarm by pressing the red button on panel.<br>
                                        Make sure all windows and doors are closed and locked.
                                    </p>
                                </v-alert>
                            </template>
                            <template v-if="field == 'prop-extra'">
                                <p class="subtitle-1 mb-0 font-weight-medium secondary--text">Additional Property Notes</p>
                                <p class="body-2">Provide any other instructions you wish others to know about caring for your property.</p>
                                <v-alert dense class="body-2 text-left mb-0" color="#666" outlined>
                                    <span class="caption secondary--text">Examples:</span>
                                    <p class="mb-0">
                                        Move car into back yard and lock. Keys on kitchen wall.<br>
                                        Shut off water if temperatures are below freezing.<br>
                                        Ask neighbors to bring in any packages left outside.
                                    </p>
                                </v-alert>
                            </template>
                            <!-- People -->
                            <template v-if="field == 'people-tempcare'">
                                <p class="subtitle-1 mb-0 font-weight-medium secondary--text">Temporary Care Provider</p>
                                <p class="body-2">Provide the name(s) and contact information of those you trust in placing your dependent's care into.</p>
                                <v-alert dense class="body-2 text-left mb-0" color="#666" outlined>
                                    <span class="caption secondary--text">Example:</span>
                                    <p class="mb-0">
                                        Eloise (my mother)<br>
                                        Mobile: (888) 555-5555<br>
                                        She is familiar with caring for them.
                                    </p>
                                </v-alert>
                            </template>
                            <template v-if="field == 'people-dailycare'">
                                <p class="subtitle-1 mb-0 font-weight-medium secondary--text">Daily Care Routine</p>
                                <p class="body-2">Describe the daily care routine for your dependent.</p>
                                <v-alert dense class="body-2 text-left mb-0" color="#666" outlined>
                                    <span class="caption secondary--text">Example:</span>
                                    <p class="mb-0">
                                        Eats normal/adult meals, with snacks in between.<br>
                                        Bath and PJ's before bed (located in her bottom drawer).<br>
                                        Likes to be read to (usually 10 minutes) while sleeping.
                                    </p>
                                </v-alert>
                            </template>
                            <template v-if="field == 'people-schooling'">
                                <p class="subtitle-1 mb-0 font-weight-medium secondary--text">Nursery/School/Etc.</p>
                                <p class="body-2">Describe the schedules and locations of daily activities.</p>
                                <v-alert dense class="body-2 text-left mb-0" color="#666" outlined>
                                    <span class="caption secondary--text">Example:</span>
                                    <p class="mb-0">
                                        Goes to Alexander Elementary School.<br>
                                        Drop-off at 8am, pick-up at 3pm.<br>
                                        Make sure she has her bookbag!
                                    </p>
                                </v-alert>
                            </template>
                            <template v-if="field == 'people-careproviders'">
                                <p class="subtitle-1 mb-0 font-weight-medium secondary--text">Care Providers</p>
                                <p class="body-2">List names and contact information of your dependent's care providers.</p>
                                <v-alert dense class="body-2 text-left mb-0" color="#666" outlined>
                                    <span class="caption secondary--text">Example:</span>
                                    <p class="mb-0">
                                        Pediatrician is Dr. Womack<br>
                                        Center-City Pediatrics<br>
                                        (888) 555-5555
                                    </p>
                                </v-alert>
                            </template>
                            <template v-if="field == 'people-medications'">
                                <p class="subtitle-1 mb-0 font-weight-medium secondary--text">Medications</p>
                                <p class="body-2">List the names and dosage information of your dependent's medications.</p>
                                <v-alert dense class="body-2 text-left mb-0" color="#666" outlined>
                                    <span class="caption secondary--text">Example:</span>
                                    <p class="mb-0">
                                        Acetametaphin / 50mg.<br>
                                        Take as-needed for headaches.<br>
                                        Located above refridgerator.
                                    </p>
                                </v-alert>
                            </template>
                            <template v-if="field == 'people-conditions'">
                                <p class="subtitle-1 mb-0 font-weight-medium secondary--text">Medical Conditions</p>
                                <p class="body-2">List any medical conditions your dependent's care providers should be aware of.</p>
                                <v-alert dense class="body-2 text-left mb-0" color="#666" outlined>
                                    <span class="caption secondary--text">Example:</span>
                                    <p class="mb-0">
                                        Has irregular heartbeat.<br>
                                        Diagnosed with colon cancer in 2018.
                                    </p>
                                </v-alert>
                            </template>
                            <template v-if="field == 'people-allergies'">
                                <p class="subtitle-1 mb-0 font-weight-medium secondary--text">Allergies</p>
                                <p class="body-2">List any food/medical/etc. allergies your dependent has.</p>
                                <v-alert dense class="body-2 text-left mb-0" color="#666" outlined>
                                    <span class="caption secondary--text">Example:</span>
                                    <p class="mb-0">
                                        Allergic to penicillin!<br>
                                        Mild allergy to peanuts.
                                    </p>
                                </v-alert>
                            </template>
                            <template v-if="field == 'people-extra'">
                                <p class="subtitle-1 mb-0 font-weight-medium secondary--text">Additional Depenedent Information</p>
                                <p class="body-2">List any additional notes you would like to pass on to your dependent's care providers.</p>
                                <v-alert dense class="body-2 text-left mb-0" color="#666" outlined>
                                    <span class="caption secondary--text">Example:</span>
                                    <p class="mb-0">
                                        If Sarah becomes restless, she can easily be calmed by watching one of her favorite cartoons. Just ask her what she wants to watch!
                                    </p>
                                </v-alert>
                            </template>                        
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions class="card-actions secondary">
                <v-spacer></v-spacer>
                <v-btn color="primary" dark class="px-10" @click.native="close()">Got It</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

export default {

    name: 'NotesExamples',
    props: {
        field: {
            required: true,
            type: String
        }
    },
    computed: {
    },
    data: () => ({
    }),
    methods: {
        close(){
          this.$emit('closed', true);
        }     
    },
    mounted(){
    },
    watch: {
    }
}
</script>
