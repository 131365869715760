<template>
    <div>
        <v-card class="ma-0 mx-md-0 my-md-0" :tile="$vuetify.breakpoint.smAndDown">
            <v-card-title class="card-header text-truncate text-center text-md-left page">
                <v-icon color="primary" class="mr-1 hidden-sm-and-down">fa fa-edit</v-icon> Manage {{ getType(item.type) }}
            </v-card-title>
            <v-alert class="mt-2" dense type="error" v-if="errors">
                {{ errors }}
            </v-alert>
            <!-- <v-tabs v-model="view" centered slider-color="primary" slider-size="3" color="primary" background-color="#eee">
                <v-tab><v-icon small :color="(view==0) ? 'primary' : 'grey'" class="mr-2">fa fa-file-alt</v-icon> Contents</v-tab>
                <v-tab><v-icon small :color="(view==1)? 'primary' : 'grey'" class="mr-1">fa fa-paperclip</v-icon>  Attachments</v-tab>
            </v-tabs> -->

            <v-card-text class="px-0 px-md-0 pt-0 pb-0">
                <template v-if="item.type == 1">
                    <Medical :item="item" @saved="handleItemSave($event)" @cancel="$emit('cancel')" />
                </template>
                <template v-if="item.type == 2">
                    <People :item="item" @saved="handleItemSave($event)" @cancel="$emit('cancel')" />
                </template>
                <template v-if="item.type == 3">
                    <Pet :item="item" @saved="handleItemSave($event)" @cancel="$emit('cancel')" />
                </template>
                <template v-if="item.type == 4">
                    <Property :item="item" @saved="handleItemSave($event)" @cancel="$emit('cancel')" />
                </template>
                <template v-if="item.type == 5">
                    <Default :item="item" @saved="handleItemSave($event)" @updated="$emit('updated')" @cancel="$emit('cancel')" />
                </template>
                <template v-if="1==2">
                    <v-row class="mt-0">
                        <v-col cols="12" md="12" lg="12" class="pt-0" offset-lg="0" v-if="view==0">
                            <v-textarea
                                v-model="item.body"
                                label="Note Contents"
                                placeholder="Ex: Details related to this note..."
                                :rules="[rules.required]"
                                validate-on-blur
                                outlined
                                rows="4"
                                auto-grow
                                hide-details
                                class="mb-0 hidden"
                                style="max-height: 500px;"
                            />
                            <tiptap-vuetify
                                v-model="item.body"
                                :extensions="extensions"
                                :placeholder="getInitText(item.type)"
                            />
                        </v-col>
                        <v-col cols="12" class="pt-0 px-5 px-md-8" v-else>
                            <!-- <v-card>
                                <v-card-text> -->
                                    <p class="subtitle-1 mt-2 secondary--text text-center text-md-left font-weight-medium mb-0"><v-icon color="primary" class="mr-1" small>fa fa-paperclip</v-icon> Attachments:</p>
                                    <v-divider class="mt-1 mb-2" />
                                    <p v-if="!files.length" class="body-2 text-center text-md-left">Add a photo or document to help your contacts better understand your notes and instructions.</p>
                                    <!-- <Files v-if="1==2 && filesReady" :files="files" @sorted="filesSorted=true" @updated="files=$event" /> -->
                                <!-- </v-card-text>
                            </v-card> -->
                        </v-col>
                    </v-row>
                </template>
            </v-card-text>
            <!-- <v-card-actions class="card-actions mt-1" id="form-actions" v-intersect="onIntersect">
                <v-spacer />
                <v-btn text @click.native="$emit('cancel')">Cancel</v-btn>
                <v-btn color="primary" :loading="saving" :disabled="!canSubmit" dark class="px-5 ml-5" @click.native="save()">Save Note</v-btn>
                <v-spacer />
            </v-card-actions> -->
        </v-card>
    </div>
</template>
<script>
    
import { mapState } from 'vuex'
// import Files from '@/components/user/notes/Files'
import Default from '@/components/user/notes/types/Default'
import Medical from '@/components/user/notes/types/Medical'
import People from '@/components/user/notes/types/People'
import Pet from '@/components/user/notes/types/Pet'
import Property from '@/components/user/notes/types/Property'
import { TiptapVuetify, Heading, Bold, Italic, Underline, BulletList, ListItem, Link, HardBreak } from 'tiptap-vuetify'

export default {
    name: 'UserContactsUpdate',
    props: {
        item: {
            required: true,
            type: Object
        }
    },
    components: {
        // Files,
        Default,
        Medical,
        People,
        Pet,
        Property,
        TiptapVuetify
    },
    computed: {
        ...mapState(['user']),
        canSubmit(){
            let body = this.item.body.replace(/<[^/>][^>]*><\/[^>]+>/, ''); 
            return body.length > 0;
        }
    },
    data: () => ({
        errors: null,
        formData: null,
        files: [],
        filesSorted: false,
        formAxns: null,
        formAxnsOffScreen: false,
        itemCopy: {},
        rules: {
            required: value => !!value || 'Required.',
            minlength: value => value && value.length > 5 || 'Must be at least 6 characters.',
        },
        showAppBar: false,
        types: [
            { value: 1, text: 'Medical Alert'},
            { value: 3, text: 'Child/Family Care'},
            { value: 2, text: 'Pet Care'},
            { value: 4, text: 'Property Notes'},
            { value: 5, text: 'Vehicle Information'},
            { value: 99, text: 'Other...'},
        ],
        filesReady: false,
        photo: null,
        saving: false,
        extensions: [
            Bold,
            Italic,
            Underline,
            ListItem,
            BulletList,
            // OrderedList,
            [Heading, {
                options: {
                levels: [1, 2, 3]
                }
            }],
            // HorizontalRule,
            Link,
            HardBreak
        ],
        view: 0,
    }),
    methods: {
        initData(){
            this.itemCopy = JSON.parse(JSON.stringify(this.item));
            this.files = JSON.parse(JSON.stringify(this.item.files));
            this.filesReady = true;
            this.formData = new FormData();
            setTimeout(() => {
                this.initAxnsBar();
            }, 1000);
        },
        initAxnsBar(){
            this.formAxns = document.getElementById('form-actions');
            if(this.formAxns){
                if(this.formAxns.offsetTop > window.innerHeight){
                    this.formAxnsOffScreen = true;
                    this.showAppBar = true;
                }
            }
        },
        onIntersect(e){
            if(this.formAxnsOffScreen){
                this.showAppBar = !e[0].isIntersecting;
            }
        },
        getType(idx){
            if(idx == 1){
                return 'Medical Notes';
            }else if(idx == 2){
                return 'Child/Family Care Notes';
            }else if(idx == 3){
                return 'Pet Care Notes';
            }else if(idx == 4){
                return 'Property Notes';
            }

            return 'Your Emergency Notes';
        },
        getInitText(idx){
            if(idx == 1){
                return 'Add any medication allergies or medical conditions you may have.';
            }else if(idx == 2){
                return 'List any actions needed for those left in your care.';
            }else if(idx == 3){
                return 'List any instructions for feeding and caring for your pets.';
            }else if(idx == 4){
                return 'Add notes for accessing and caring for your home and plants.';
            }

            return 'Add any other notes you wish to share with your emergecy contacts.';
        },
        handleItemSave(data){
            this.item.body = JSON.stringify(data);
            this.save();
        },
        save(){ 
            this.saving = true;
            this.errors = null;
            this.formData.append('type', this.item.type);
            this.formData.append('title', this.item.title);
            this.formData.append('body', this.item.body);

            // handle files
            if(this.files.length){
                for(let i in this.files){
                    if(this.files[i].isNew) {
                        this.formData.append('file_' + i, this.files[i].file);
                        this.formData.append('file_' + i + '_caption', this.files[i].caption || '');
                        this.formData.append('file_' + i + '_sort', i);
                    }
                }
                if(this.filesSorted){
                    let sorts = [];
                    for(let i in this.files){
                        if(this.files[i].uuid){
                            sorts.push(this.files[i].uuid);
                        }else{
                            sorts.push(null);
                        }
                    }
                    this.formData.append('sorts', JSON.stringify(sorts));
                }
            }

            // augment request if new item vs. existing
            let endpoint = (this.item.uuid) ? '/' + this.item.uuid : '';
            if(this.item.uuid){
                this.formData.append('_method', 'put');
            }

            let request = {
                endpoint: '/notes' + endpoint, 
                method: 'post',
                data: this.formData,
                apiKey: this.user.api_token || null,
                header: { 'Content-Type': 'multipart/form-data' },
            };
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 200 || res.status == 201){
                    this.$eventHub.$emit('refreshUser');
                    this.$eventHub.$emit('snackBar', {
                        text: 'Your notes have been updated!'
                    });
                    this.$emit('updated');
                    if(!this.item.uuid){
                        this.item.uuid = res.data.uuid;
                    }
                }else{
                    this.handleErrorResponse(res);
                }
            }).catch(()=>{
                this.errors = "Something went wrong! Please try again.";
            }).finally(()=>{
                this.saving = false;
                this.$router.push('/app/notes');
            });
        },
        handleErrorResponse(res){
            this.errors = '';
            for(let i in res.data.errors){
                this.errors += res.data.errors[i];
            }
        },
        setFormImage(){
            fetch(this.photo)
            .then((response) => {
                return response.blob();
            }).then((blob) => {
                this.formData.append('image', blob);
                // this.uploadPhoto(true);
            });
        },
        handlePhotoRemoved(){
            if(this.item.image){
                this.photo = null;
                this.item.image = null;
                this.formData.append('image_removed', true);
                this.formData.delete('image');
            }
        },
    },
    beforeMount(){
        this.$eventHub.$on('footerInt', (visible) => {
            this.showAppBar = !visible;
        });
    },
    beforeDestroy(){
        // if(this.itemCopy.body != this.item.body && !this.saving){
        //     let confirmVal = confirm('You have unsaved changed!');
        //     if(confirmVal){
        //         this.$eventHub.$off('footerInt');
        //         this.$emit('cancel');
        //         this.$eventHub.$emit('refreshUser');
        //     }else{
        //         this.item = JSON.parse(JSON.stringify(this.itemCopy));
        //         this.$router.push('/app/notes/' + this.item.uuid);
        //     }
        // }
    },
    mounted(){
		this.initData();
	},
    watch: {
        files(){
            setTimeout(() => {
                this.initAxnsBar();
            }, 500);
        }
    }
}
</script>
<style>
  @import '../../../../node_modules/tiptap-vuetify/dist/main.css';
</style>