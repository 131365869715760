<template>
    <div>
        <v-card class="ma-0 mx-md-0 my-md-0" flat v-if="ready" :disabled="loading" :loading="loading">
            <v-card-text class="px-0">
                <template v-if="!adding && !updating">
                    <v-row class="mt-0" v-if="!content.length">
                        <v-col cols="12" class="pt-0">
                            <p class="text-center my-10">
                                <v-icon color="primary" x-large>fa fa-dog</v-icon>
                                <br>
                                <v-btn color="primary" outlined @click.native="handleAdd()" rounded class="mt-3">Add Your First Pet</v-btn>
                            </p>
                        </v-col>
                    </v-row>
                    <v-row class="mt-0" v-else>
                        <v-col cols="12" class="pt-0">
                            <v-tabs v-model="view" centered slider-color="primary" height="50" slider-size="3" class="tabs-bold" color="primary" background-color="#eee" style="margin-top: -17px; border-top: 1px solid #ddd;">
                                <v-tab><v-icon small :color="(view==0) ? 'primary' : 'grey'" class="mr-2">fa fa-list</v-icon> Your Pets</v-tab>
                                <v-tab><v-badge inline :color="(fileCount>0) ? 'primary' : 'grey'" :content="fileCount || '0'"><v-icon small :color="(view==1)? 'primary' : 'grey'" class="mr-1">fa fa-paperclip</v-icon>  Attachments</v-badge></v-tab>
                            </v-tabs>
                            <v-divider />
                            <v-alert v-if="view!==1 && (!fileCount || fileCount < 1)" dense type="info" class="body-2 mt-3 mx-2 mx-md-5 mb-0">
                                You can add photos and documents for your pets via the "Attachments" tab above.
                            </v-alert>
                            <template v-if="view==0">
                                <v-list class="mx-2 pt-0 mt-2">
                                    <template v-for="contentItem, idx in content">
                                        <v-list-item :key="'pet' + idx" @click="handleUpdate(idx)">
                                            <v-list-item-avatar color="primary">
                                                <v-icon color="white">{{ getIcon(contentItem) }}</v-icon>
                                            </v-list-item-avatar>
                                            <v-list-item-content>
                                                <v-list-item-title class="font-weight-medium">{{ contentItem.name }}</v-list-item-title>
                                                <v-list-item-subtitle>{{ contentItem.type }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                            <v-list-item-action>
                                                <v-icon color="primary">fa fa-edit</v-icon>
                                            </v-list-item-action>
                                        </v-list-item>
                                    </template>
                                </v-list>
                                <v-divider class="mb-3" />
                                <p class="text-center">
                                    <v-btn color="primary" @click.native="handleAdd()" rounded class="mt-3">
                                        <v-icon small color="white" class="mr-2">fa fa-plus-circle</v-icon>
                                        Add Another Pet
                                    </v-btn>
                                </p>
                            </template>
                            <template v-else>
                                <Files :item="item" @fileCount="fileCount=$event" @loading="loading=$event" />
                            </template>
                        </v-col>
                    </v-row>
                </template>
                <template v-else>
                    <v-row>
                        <v-col cols="12" class="pt-0">
                            <template v-if="adding">
                                <h2 class="title px-6 mt-3 secondary--text">Add a pet:</h2>
                                <p class="subtitle-1 lh-12 secondary--text px-6 mb-0"
                                    >We're going to ask for a lot of details about each of your pets so they can be fully cared for in the event of your absence. Please be as detailed as possible to ensure your pet's well-being.
                                </p>
                                <v-divider class="mt-4" />
                            </template>
                            <template v-if="updating">
                                <p class="title grey lighten-3 pl-5 pt-3 pb-0 pb-md-0 mb-0 tedxt-center text-md-left secondary--text"  style="margin-top: -5px; border-top: 1px solid #ddd !important;">
                                    <span class="d-inline-block text-truncate" style="max-width: 80%;">{{ newItem.name }}</span>
                                    <v-btn v-if="updating" class="float-right" text color="error" @click.native="removing=true;"><v-icon>fa fa-trash-alt</v-icon></v-btn>
                                </p>
                                <v-divider />
                            </template>
                            <v-stepper v-model="step" vertical class="mx-0 pb-3" flat elevation="0">
                                <v-stepper-step :complete="step > 1" step="1" editable class="pb-2">
                                    <span class="subtitle-1 lh-14 font-weight-medium">Pet Details</span>
                                    <small class="body-2" v-if="step>1">{{ newItem.name }} &bull; {{ newItem.type }}</small>
                                </v-stepper-step>
                                <v-stepper-content step="1" class="pl-0 pl-md-6">
                                    <v-row dense>
                                        <v-col cols="12">
                                            <span class="subtitle-1 text-label">What is your pet's name?</span>
                                            <v-text-field class="mt-1" dense :autofocus="!$vuetify.breakpoint.smAndDown" hide-details style="max-width: 400px;" outlined v-model="newItem.name" placeholder="Ex: Tucker (required)" />
                                        </v-col>
                                        <v-col cols="12" class="py-2">
                                            <span class="subtitle-1 text-label">What kind of pet is {{ newItem.name || 'it' }}?</span>
                                            <v-radio-group row v-model="newItem.type" hide-details class="my-0">
                                                <v-radio value="Dog" label="Dog" />
                                                <v-radio value="Cat" label="Cat" />
                                                <v-radio value="Other" label="Other" />
                                            </v-radio-group>
                                            <v-text-field v-if="newItem.type == 'Other'" dense hide-details class="mt-3" style="max-width: 400px;" :autofocus="!$vuetify.breakpoint.smAndDown" outlined v-model="newItem.typeOther" placeholder="Ex: Bird, Reptile... (required)" />
                                        </v-col>
                                    </v-row>
                                    <v-divider class="my-3" />
                                    <template v-if="!updating">
                                        <v-btn color="primary" class="mb-3" :disabled="!newItem.name || !newItem.type || (newItem.type == 'Other' && !newItem.typeOther)" @click.native="++step">Continue</v-btn>
                                    </template>
                                </v-stepper-content>

                                <v-stepper-step step="2" :complete="step>2  " :editable="newItem.name > ''" class="pb-2">
                                    <span class="subtitle-1 lh-14 font-weight-medium">Feeding {{ (newItem.name && (updated || step>1)) ? newItem.name : 'Your Pet' }}</span>
                                    <small class="body-2" v-if="step>2 || updating">{{ newItem.feedingFreq }}x Daily &bull; {{ newItem.feedingQty }} {{ newItem.feedingUnit }}</small>
                                </v-stepper-step>
                                <v-stepper-content step="2" class="pl-0 pl-md-6 pb-3">
                                    <v-row dense>
                                        <v-col cols="12">
                                            <span class="subtitle-1 text-label">How often should {{ newItem.name }} be fed?</span>
                                            <v-select dense hide-details class="mt-1" style="max-width: 400px;" :items="feedingFreq" outlined v-model="newItem.feedingFreq" />
                                        </v-col>
                                        <v-col cols="12" class="pt-2">
                                            <span class="subtitle-1 text-label">How much should {{ newItem.name }} be fed per serving?</span>
                                            <v-select dense hide-details class="mt-1 mr-3 d-inline-block" style="max-width: 100px;" outlined v-model="newItem.feedingQty" :items="feedingQty" />
                                            <v-select dense hide-details class="mt-1 d-inline-block" style="max-width: 150px;" outlined v-model="newItem.feedingUnit" :items="feedingUnits" />
                                        </v-col>
                                        <v-col cols="12" class="pt-2">
                                            <span class="subtitle-1 text-label mb-1">What kind of food does {{ newItem.name }} eat?</span>
                                            <v-text-field hide-details style="max-width: 400px;" dense outlined v-model="newItem.feedingBrand" placeholder="Ex: 'Purina', 'any dry food', etc... (required)" />
                                        </v-col>
                                        <v-col cols="12" class="pt-2">
                                            <span class="subtitle-1 text-label mb-1">Where is their food located?</span>
                                            <v-text-field hide-details style="max-width: 400px;" dense outlined v-model="newItem.feedingLocation" placeholder="Ex: Pantry... (required)" />
                                        </v-col>
                                        <v-col cols="12" class="pt-2">
                                            <span class="subtitle-1 text-label mb-1">What else would you like others to know about feeding {{ newItem.name }}?</span>
                                            <v-textarea rows="2" hide-details dense outlined v-model="newItem.feedingExtra" placeholder="Ex: Treats, special circumstances... (optional)" />
                                            <v-icon color="primary" class="mr-1" x-small>fa fa-info-circle</v-icon><small @click="example='pet-feeding'" class="action-item caption primary--text">See Example</small>
                                        </v-col>
                                    </v-row>
                                    <v-divider class="my-3" />
                                    <v-btn color="primary" v-if="!updating" class="mb-3" :disabled="!newItem.feedingLocation || !newItem.feedingBrand" @click.native="++step">Continue</v-btn>
                                </v-stepper-content>

                                <v-stepper-step step="3" :editable="newItem.feedingBrand > ''" class="pb-2">
                                    <span class="subtitle-1 lh-14 font-weight-medium">Caring for {{ (newItem.name) ? newItem.name : 'Your Pet' }}</span>
                                    <small class="body-2" v-if="updating">Potty details provided.</small>
                                </v-stepper-step>
                                <v-stepper-content step="3" class="pl-0 pl-md-6">
                                    <v-row dense>
                                        <v-col cols="12">
                                            <span class="subtitle-1 text-label mb-1 ">What's the potty routine for {{ newItem.name }}?</span>
                                            <v-textarea rows="2" hide-details dense outlined v-model="newItem.potty" :placeholder="'Ex: ' + newItem.name + ' goes potty in the back yard three times a day... (required)'" />
                                            <v-icon color="primary" class="mr-1" x-small>fa fa-info-circle</v-icon><small @click="example='pet-potty'" class="action-item caption primary--text">See Example</small>
                                        </v-col>
                                        <v-col cols="12" class="pt-2">
                                            <span class="subtitle-1 text-label mb-1">Where are their accessories located?</span>
                                            <v-textarea rows="1" hide-details dense outlined v-model="newItem.accessories" placeholder="Ex: Leash and harness are located in bottom drawer of... (optional)" />
                                        </v-col>
                                        <v-col cols="12" class="pt-2">
                                            <span class="subtitle-1 text-label mb-1">Who provides vet/medical care for {{ newItem.name }}?</span>
                                            <v-textarea rows="2" hide-details dense outlined v-model="newItem.medical" placeholder="Ex: Main Street Animal Hospital, located at... (optional)" />
                                            <v-icon color="primary" class="mr-1" x-small>fa fa-info-circle</v-icon><small @click="example='pet-providers'" class="action-item caption primary--text">See Example</small>
                                        </v-col>
                                        <v-col cols="12" class="pt-2">
                                            <span class="subtitle-1 text-label mb-1">What else should others know about caring for {{ newItem.name }}?</span>
                                            <v-textarea rows="2" hide-details dense outlined v-model="newItem.careExtra" placeholder="Ex: Playtime, Sleeping arrangements, Boarding, Medications, etc... (optional)" />
                                            <v-icon color="primary" class="mr-1" x-small>fa fa-info-circle</v-icon><small @click="example='pet-other'" class="action-item caption primary--text">See Example</small>
                                        </v-col>
                                    </v-row>
                                    <v-divider class="my-3 hidden-sm-and-down" v-if="!updating" />
                                    <v-btn color="primary" v-if="!updating" :disabled="!newItem.potty || !newItem.potty.length" @click.native="save()">Save {{ newItem.name }}</v-btn>
                                </v-stepper-content>

                            </v-stepper>
                            
                        </v-col>
                    </v-row>
                </template>
            </v-card-text>
            <v-card-actions class="card-actions mt-1" id="form-actions">
                <v-spacer />
                <v-btn v-if="(adding || updating) && content.length" text @click.native="adding=false; updating=false;">Cancel</v-btn>
                <v-btn v-if="(adding || updating) && !content.length" text @click.native="$emit('cancel')">Cancel</v-btn>
                <v-btn v-if="(content.length && !adding && !updating) || !content.length" outlined text @click.native="$emit('cancel')">Back to Notes</v-btn>
                <v-btn v-if="updating" :disabled="!canSave" class="px-5" color="primary" @click.native="save()">Save</v-btn>
                <v-spacer />
            </v-card-actions>
        </v-card>
        <Confirm v-if="removing" title="Remove Pet" :subtitle="'Removing ' + newItem.name + '...'" msg="Are you sure you want to remove this pet?" @confirmed="handleDelete()" @closed="removing=false" />
        <NotesExamples v-if="example" :field="example" @closed="example=null" />
    </div>
</template>
<script>
    
import { mapState } from 'vuex'
import Confirm from '@/components/common/Confirm'
import Files from '@/components/user/notes/Files'
import NotesExamples from '@/components/common/NotesExamples'

export default {
    name: 'UserNotesUpdatePet',
    props: {
        item: {
            required: true,
            type: Object
        }
    },
    components: {
        Confirm,
        Files,
        NotesExamples
    },
    computed: {
        ...mapState(['user']),
        canSave(){
            return this.newItem.name.length > 0 && this.newItem.feedingLocation.length > 0 
            && this.newItem.feedingBrand.length > 0 && this.newItem.potty.length > 0;
        }
    },
    data: () => ({
        adding: false,
        example: null,
        feedingFreq: [
            {
                value: 1,
                text: 'Once Daily'
            },
            {
                value: 2,
                text: 'Twice Daily'
            },
            {
                value: 3,
                text: 'Three Times Daily'
            },
            {
                value: 4,
                text: 'Four Times Daily'
            },
            {
                value: 5,
                text: 'Five Times Daily'
            },
            {
                value: 6,
                text: 'Six Times Daily'
            }
        ],
        feedingUnits: [
            {
                value: 'cup',
                text: 'Cup'
            },
            {
                value: 'ounce',
                text: 'Ounce'
            },
            {
                value: 'pound',
                text: 'Pound'
            },
            {
                value: 'package',
                text: 'Package'
            },

        ],
        feedingQty: [
            1, 2, 3, 4, 5, 6
        ],
        fileCount: 0,
        loading: false,
        newItem: {
            type: 'Dog',
            feedingFreq: 3,
            feedingQty: 1,
            feedingUnit: 'cup',
        },
        newItemCopy: {},
        content: [],
        errors: null,
        itemCopy: {},
        ready: false,
        removing: false,
        rules: {
            required: value => !!value || 'Required.',
            minlength: value => value && value.length > 5 || 'Must be at least 6 characters.',
        },
        step: 1,
        updating: false,
        updatingIdx: null,
        view: 0,
    }),
    methods: {
        initData(){
            this.itemCopy = JSON.parse(JSON.stringify(this.item));
            this.newItemCopy = JSON.parse(JSON.stringify(this.newItem));
            this.fileCount = this.item.files.length;
            if(this.itemCopy.body){
                this.content = JSON.parse(this.itemCopy.body);
            }
            this.ready = true;
        },
        handleAdd(){
            this.step=1;
            this.newItem = JSON.parse(JSON.stringify(this.newItemCopy));
            this.adding = true;
            this.updating = false;
        },
        handleUpdate(idx){
            this.step=1;
            this.updatingIdx = idx;
            this.newItem = this.content[idx];
            this.updating = true;
            this.adding = false;
        },
        handleDelete(){
            if(this.content.length > 1){
                this.content.splice(this.updatingIdx, 1);
            }else{
                // delete entire note
                this.handleDeleteNote();
                return;
            }
            this.updating = false;
            this.removing = false;
            this.save();
        },
        handleDeleteNote(){
            this.loading = true;
            let request = {
                endpoint: '/notes/' + this.item.uuid, 
                method: 'post',
                data: {
                    '_method' : 'delete'
                },
                apiKey: this.user.api_token || null
            };
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 204){
                    this.$eventHub.$emit('snackBar', {
                        text: 'Your notes have been removed.'
                    });
                    this.$eventHub.$emit('refreshUser');
                    this.$emit('cancel');
                }
            }).catch(()=>{
            }).finally(()=>{
                this.loading = false;
            });
        },
        save(){
            if(this.adding){
                this.content.push(this.newItem);
            }
            if(this.updating){
                this.content[this.updatingIdx] = this.newItem;
            }
            this.$emit('saved', this.content);
            this.updating = false;
            this.updatingIdx = null;
            this.adding = false;
        },
        getIcon(item){
            
            if(item.type == 'Dog'){
                return 'fa fa-dog';
            }
            
            if(item.type == 'Cat'){
                return 'fa fa-cat';
            }

            return 'fa fa-feather';
        }
    },
    beforeMount(){
        this.$eventHub.$on('footerInt', (visible) => {
            this.showAppBar = !visible;
        });
    },
    beforeDestroy(){
        
    },
    mounted(){
		this.initData();
	},
    watch: {
    }
}
</script>