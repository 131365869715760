<template>
    <div>
        <v-card class="ma-0 mx-md-0 my-md-0" flat v-if="ready" :disabled="loading" :loading="loading">
            <v-card-text class="px-0">
                <template v-if="!adding">
                    <v-row class="mt-0">
                        <v-col cols="12" class="pt-0">
                            <v-tabs v-model="view" centered slider-color="primary" height="50" slider-size="3" class="tabs-bold" color="primary" background-color="#eee" style="margin-top: -17px; border-top: 1px solid #ddd;">
                                <v-tab><v-icon small :color="(view==0) ? 'primary' : 'grey'" class="mr-2">fa fa-list</v-icon> Notes</v-tab>
                                <v-tab><v-badge inline :color="(fileCount>0) ? 'primary' : 'grey'" :content="fileCount || '0'"><v-icon small :color="(view==1)? 'primary' : 'grey'" class="mr-1">fa fa-paperclip</v-icon>  Attachments</v-badge></v-tab>
                            </v-tabs>
                            <v-divider />
                            <v-alert v-if="view!==1 && (!fileCount || fileCount < 1)" dense type="info" class="body-2 mt-3 mb-0 mx-2 mx-md-5">
                                You can add photos and documents related to your medical care via the "Attachments" tab above.
                            </v-alert>
                            <template v-if="view==1">
                                <Files :item="item" @fileCount="fileCount=$event" @loading="loading=$event" />
                            </template>
                        </v-col>
                    </v-row>
                </template>
                <template v-if="view!==1">
                    <v-row class="mt-0">
                        <v-col cols="12" class="pt-0">
                            <template v-if="!item.body">
                                <h2 class="title px-6 secondary--text">Add your Medical Notes:</h2>
                                <p class="subtitle-1 lh-12 secondary--text px-6 mb-2">
                                    Provide enough details so that your emergency contacts can clearly understand your medical information and relay it to those providing care to you.
                                </p>
                                <p class="subtitle-1 lh-12 secondary--text px-6 mb-0">
                                    <b>Privacy Note: </b> This information is only viewable to your emergency contacts in the event you fail to check-in.
                                </p>
                                <v-divider class="mt-4" />
                            </template>
                            <v-stepper v-model="step" vertical class="mx-0 pb-3" flat elevation="0">
                                <v-stepper-step :complete="step > 1" step="1" editable class="pb-2">
                                    <span class="subtitle-1 lh-14 font-weight-medium">Medication &amp; Allergies</span>
                                    <small class="body-2" v-if="step>1">Medications: {{ newItem.medications }} &bull; Allergies: {{ newItem.allergies }}</small>
                                </v-stepper-step>
                                <v-stepper-content step="1" class="pl-0 pl-md-6">
                                    <v-row dense>
                                        <v-col cols="12" class="pb-2 pt-0">
                                            <span class="subtitle-1 text-label">Are you currently taking any medications (including over-the-counter)?</span>
                                            <v-radio-group row v-model="newItem.medications" hide-details class="my-0">
                                                <v-radio value="Yes" label="Yes" />
                                                <v-radio value="No" label="No" />
                                            </v-radio-group>
                                            <v-textarea class="mt-2" rows="2" hide-details auto-grow dense outlined v-if="newItem.medications == 'Yes'" v-model="newItem.medicationsDesc" placeholder="List your medications and dosage... (required)" />
                                            <template v-if="newItem.medications == 'Yes'">
                                                <v-icon color="primary" class="mr-1" x-small>fa fa-info-circle</v-icon><small @click="example='medical-medications'" class="action-item caption primary--text">See Example</small>
                                            </template>
                                        </v-col>
                                        <v-col cols="12" class="pb-2">
                                            <span class="subtitle-1 text-label">Are you allergic to any medications?</span>
                                            <v-radio-group row v-model="newItem.allergies" hide-details class="my-0">
                                                <v-radio value="Yes" label="Yes" />
                                                <v-radio value="No" label="No" />
                                            </v-radio-group>
                                            <v-textarea rows="2" class="mt-2" hide-details auto-grow dense outlined v-if="newItem.allergies == 'Yes'" v-model="newItem.allergiesDesc" placeholder="List all known allergies... (required)" />
                                            <template v-if="newItem.allergies == 'Yes'">
                                                <v-icon color="primary" class="mr-1" x-small>fa fa-info-circle</v-icon><small @click="example='medical-allergies'" class="action-item caption primary--text">See Example</small>
                                            </template>
                                        </v-col>
                                    </v-row>
                                    <v-divider class="my-3" />
                                    <template v-if="!updating">
                                        <v-btn color="primary" class="mb-3" :disabled="!canSubmit1" @click.native="++step">Continue</v-btn>
                                    </template>
                                </v-stepper-content>

                                <v-stepper-step step="2" :complete="step>2  " :editable="item.body.length>0" class="pb-2">
                                    <span class="subtitle-1 lh-14 font-weight-medium">Health Conditions</span>
                                    <small class="body-2" v-if="step>2 || updating">Health Conditions: {{ newItem.conditions }}</small>
                                </v-stepper-step>
                                <v-stepper-content step="2" class="pl-0 pl-md-6 pb-3">
                                    <v-row dense>
                                        <v-col cols="12" class="pb-2 pt-0">
                                            <span class="subtitle-1 text-label">Do you currently have any health conditions?</span>
                                            <v-radio-group row v-model="newItem.conditions" hide-details class="my-0">
                                                <v-radio value="Yes" label="Yes" />
                                                <v-radio value="No" label="No" />
                                            </v-radio-group>
                                            <v-textarea class="mt-2" rows="2" hide-details auto-grow dense outlined v-if="newItem.conditions == 'Yes'" v-model="newItem.conditionsDesc" placeholder="Describe your health conditions... (required)" />
                                            <template v-if="newItem.conditions == 'Yes'">
                                                <v-icon color="primary" class="mr-1" x-small>fa fa-info-circle</v-icon><small @click="example='medical-conditions'" class="action-item caption primary--text">See Example</small>
                                            </template>
                                        </v-col>
                                        <v-col cols="12" class="pb-2">
                                            <span class="subtitle-1 text-label">Is there any other medical information you would your caregivers to know?</span>
                                            <v-radio-group row v-model="newItem.other" hide-details class="my-0">
                                                <v-radio value="Yes" label="Yes" />
                                                <v-radio value="No" label="No" />
                                            </v-radio-group>
                                            <v-textarea rows="2" class="mt-2" hide-details auto-grow dense outlined v-if="newItem.other == 'Yes'" v-model="newItem.otherDesc" placeholder="List any additional information that should be relayed to your care provider(s)... (required)" />
                                            <template v-if="newItem.other == 'Yes'">
                                                <v-icon color="primary" class="mr-1" x-small>fa fa-info-circle</v-icon><small @click="example='medical-other'" class="action-item caption primary--text">See Example</small>
                                            </template>
                                        </v-col>
                                    </v-row>
                                    <v-divider class="my-3" />
                                    <v-btn color="primary" v-if="!updating" class="mb-3" :disabled="!canSubmit2" @click.native="++step">Continue</v-btn>
                                </v-stepper-content>

                                <v-stepper-step step="3" :editable="item.body.length>0" class="pb-2">
                                    <span class="subtitle-1 lh-14 font-weight-medium">Healthcare Providers</span>
                                    <small class="body-2" v-if="updating">{{ (newItem.providers) ? 'Details provided' : 'No details provided.' }}</small>
                                </v-stepper-step>
                                <v-stepper-content step="3" class="pl-0 pl-md-6">
                                    <v-row dense>
                                        <v-col cols="12" class="pt-0">
                                            <span class="subtitle-1 text-label mb-1 ">List your primary or other care providers:</span>
                                            <v-textarea rows="4" hide-details auto-grow dense outlined v-model="newItem.providers" placeholder="Include contact information when possible... (optional)" />
                                            <v-icon color="primary" class="mr-1" x-small>fa fa-info-circle</v-icon><small @click="example='medical-providers'" class="action-item caption primary--text">See Example</small>
                                        </v-col>
                                    </v-row>
                                    <v-divider class="my-3 hidden-sm-and-down" v-if="!updating" />
                                    <v-btn color="primary" v-if="!updating" @click.native="save()">Save Medical Notes</v-btn>
                                </v-stepper-content>

                            </v-stepper>

                            <p class="text-center mb-0 mt-3 pl-md-6" v-if="updating">
                                <v-btn color="error" small text outlined @click.native="removing=true">Delete all Medical Notes</v-btn>
                            </p>
                            
                        </v-col>
                    </v-row>
                </template>
            </v-card-text>
            <v-card-actions class="card-actions mt-1">
                <v-spacer />
                <v-btn v-if="adding" outlined text @click.native="$emit('cancel')">Cancel</v-btn>
                <v-btn v-if="updating" outlined text @click.native="$emit('cancel')">Back to Notes</v-btn>
                <v-btn v-if="updating" :disabled="!canSave" class="px-5" color="primary" @click.native="save()">Save</v-btn>
                <v-spacer />
            </v-card-actions>
        </v-card>
        <Confirm v-if="removing" title="Delete Notes" subtitle="Removing Medical Notes" msg="Are you sure you want to remove all medical notes?" @confirmed="handleDelete()" @closed="removing=false" />
        <NotesExamples v-if="example" :field="example" @closed="example=null" />
    </div>
</template>
<script>
    
import { mapState } from 'vuex'
import Confirm from '@/components/common/Confirm'
import Files from '@/components/user/notes/Files'
import NotesExamples from '@/components/common/NotesExamples'

export default {
    name: 'UserNotesUpdateDefault',
    props: {
        item: {
            required: true,
            type: Object
        }
    },
    components: {
        Confirm,
        Files,
        NotesExamples
    },
    computed: {
        ...mapState(['user']),
        canSubmit1(){
            if(this.newItem.medications == 'No' && this.newItem.allergies == 'No'){
                return true;
            }

            let canSubmit = true;
            if(this.newItem.medications !== 'No' && (!this.newItem.medicationsDesc || !this.newItem.medicationsDesc.length)){
                canSubmit = false;
            }
            if(this.newItem.allergies !== 'No' && (!this.newItem.allergiesDesc || !this.newItem.allergiesDesc.length)){
                canSubmit = false;
            }

            return canSubmit;
        },
        canSubmit2(){
            if(this.newItem.conditions == 'No' && this.newItem.other == 'No'){
                return true;
            }

            let canSubmit = true;
            if(this.newItem.conditions !== 'No' && (!this.newItem.conditionsDesc || !this.newItem.conditionsDesc.length)){
                canSubmit = false;
            }
            if(this.newItem.other !== 'No' && (!this.newItem.otherDesc || !this.newItem.otherDesc.length)){
                canSubmit = false;
            }

            return canSubmit;
        },
        canSave(){
            return true;
            // return this.newItem.name.length > 0 && this.newItem.feedingLocation.length > 0 
            // && this.newItem.feedingBrand.length > 0 && this.newItem.potty.length > 0;
        }
    },
    data: () => ({
        adding: true,
        example: null,
        fileCount: 0,
        loading: false,
        newItem: {
            allergies: 'No',
            medications: 'No',
            conditions: 'No',
            other: 'No',
        },
        newItemCopy: {},
        content: [],
        errors: null,
        itemCopy: {},
        ready: false,
        removing: false,
        rules: {
            required: value => !!value || 'Required.',
            minlength: value => value && value.length > 5 || 'Must be at least 6 characters.',
        },
        step: 1,
        updating: false,
        updatingIdx: null,
        view: 0,
    }),
    methods: {
        initData(){
            this.itemCopy = JSON.parse(JSON.stringify(this.item));
            this.newItemCopy = JSON.parse(JSON.stringify(this.newItem));
            this.fileCount = this.item.files.length;
            if(this.itemCopy.body){
                this.content = JSON.parse(this.itemCopy.body);
                this.newItem = this.content;
                this.updating = true;
                this.adding = false;
            }
            this.ready = true;
        },
        handleAdd(){
            this.step=1;
            this.newItem = JSON.parse(JSON.stringify(this.newItemCopy));
            this.adding = true;
            this.updating = false;
        },
        handleUpdate(idx){
            this.step=1;
            this.updatingIdx = idx;
            this.newItem = this.content;
            this.updating = true;
            this.adding = false;
        },
        handleDelete(){
            this.loading = true;
            let request = {
                endpoint: '/notes/' + this.item.uuid, 
                method: 'post',
                data: {
                    '_method' : 'delete'
                },
                apiKey: this.user.api_token || null
            };
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 204){
                    this.$eventHub.$emit('snackBar', {
                        text: 'Your notes have been removed.'
                    });
                    this.$eventHub.$emit('refreshUser');
                    setTimeout(() => {
                        this.$emit('cancel');
                    }, 1000);
                }
            }).catch(()=>{
            }).finally(()=>{
                this.loading = false;
            });
        },
        save(){
            this.content = this.newItem;
            this.$emit('saved', this.content);
            if(this.updating){
                this.$emit('cancel');
            }
            this.updating = true;
            this.updatingIdx = null;
            this.adding = false;
        }
    },
    beforeMount(){
        this.$eventHub.$on('footerInt', (visible) => {
            this.showAppBar = !visible;
        });
    },
    beforeDestroy(){
        
    },
    mounted(){
		this.initData();
	},
    watch: {
    }
}
</script>