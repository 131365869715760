<template>
    <div  v-if="ready" :class="(updateItem) ? '' : 'mt-2 mt-md-0'">

        <template v-if="(userNote.uuid || updateItem) && !deleteItem">
            
            <Update :item="userNote" @updated="handleItemUpdated()" @cancel="handleCancel()" />

        </template>

        <template v-else>

            <Info :items="items" v-if="!user.notes.length" />

            <template>
                <v-card v-if="user.notes.length" hover @click="$router.push('/app/notes/' + userNote.uuid)" class="elevation-2 mb-2 mx-2 mx-md-0">
                    <v-card-title class="py-2">
                        <v-icon color="primary" class="pr-2" style="min-width: 40px;">{{ getIcon(userNote.type) }}</v-icon> {{ getType(userNote.type) }}
                    </v-card-title>
                    <v-card-text class="pb-3">
                        <v-divider class="mb-3" />
                        <span class="subtitle-1 lh-12 secondary--text" v-html="getText(userNote)" />
                    </v-card-text>
                    <v-card-actions class="card-actions body-2">
                        <v-icon :color="(userNote.files.length) ? 'secondary' : 'black'" x-small class="pr-2">fa fa-paperclip</v-icon> 
                        {{ userNote.files.length || 'No' }} attachment{{ (userNote.files.length == 1) ? '' : 's' }}.
                        <v-spacer />
                        <v-btn color="primary" small @click.native="$router.push('/app/notes/' + userNote.uuid)"><v-icon color="white" small class="mr-2">fa fa-edit</v-icon> Update</v-btn>
                    </v-card-actions>
                </v-card>
                <v-card v-else hover class="elevation-2 mb-2 mx-2 mx-md-0">
                        <v-card-text class="pb-4 text-center">
                            <v-btn color="primary" large @click.native="handleAddItem(userNote)"><v-icon color="white" small class="mr-2">fa fa-plus</v-icon> Add Your First Note</v-btn>
                        </v-card-text>
                    </v-card>
            </template>
        </template>
        
        <Info :items="items" v-if="!updateItem && user.notes.length" />
		<Confirm v-if="deleteItem" title="Remove Note" :subtitle="'Remove ' + getType(deleteItem.type) + ' notes.'" msg="Are you sure you want to remove this note?" @confirmed="handleDelete()" @closed="deleteItem=null" />
    </div>
</template>
<script>
    
import { mapState, mapMutations } from 'vuex'
import Confirm from '@/components/common/Confirm'
import Info from '@/components/user/notes/Info'
import Update from '@/components/user/notes/Update'

export default {
    name: 'UserNotesList',
    props: {
        items: {
            required: true,
            type: Array
        },
    },
    components: {
        Confirm,
        Info,
        Update
    },
    computed: {
        ...mapState(['user', 'updatingItem', 'ux']),
    },
    data: () => ({
        deleting: null,
        deleteItem: null,
        initListItems: [
            { type: 2, title: "Tucker dog's feeding and vet info.", body: "Tucker's food is located in the pantry on the bottom shelf." },
            { type: 4, title: "Apartment Info", body: "Contact leasing office at the end of the main entrance. The phone number is (555) 555-5555." },
            { type: 1, title: "Medicine Allergies", body: "I am allergic to penicillin. No other allergies." },
        ],
        hasNotes: [],
        notes: [],
        loading: false,
        ready: false,
        switchOn: true,
        tempNotes: {},
        thisUx: {},
        updateItem: null,
        userNote: {},
    }),
    methods: {
        ...mapMutations(['setUpdatingItem']),
        initData(){
            if(!this.ux){
                this.thisUx = { noteTypes:[] };
            }else{
                this.thisUx = JSON.parse(JSON.stringify(this.ux));
            }
            this.notes = [];
            this.hasNotes = [];
            this.tempNotes = [];

            if(!this.user.notes.length){
                this.userNote = {
                    empty: true,
                    files: [],
                    body: '',
                    type: 5
                };
            }else{
                this.userNote = this.user.notes[0];
            }

            for(let i in this.user.notes){
                this.tempNotes[this.user.notes[i].type] = this.user.notes[i];
                this.hasNotes.push(this.user.notes[i].type);
            }
            
            // for(let i=1; i<6; ++i){
            //     if(this.hasNotes.indexOf(i) === -1){
            //         this.notes.push({
            //             empty: true,
            //             files: [],
            //             body: '',
            //             type: i
            //         });
            //     }else{
            //         // this.notes.body = JSON.parse(this.tempNotes[i].body);
            //         this.notes.push(this.tempNotes[i]);
            //     }
            // }
            if(this.updatingItem){
                for(let i in this.user.notes){
                    if(this.user.notes[i].uuid == this.updatingItem){
                        this.updateItem = this.user.notes[i];
                    }
                }
                setTimeout(() => {
                    this.setUpdatingItem(null);
                }, 50);
            }
            this.ready = true;
        },
        handleItemUpdated(){
            // this.updateItem = null;
            setTimeout(() => {
                this.initData();
            }, 1000);
        },
        getText(item){

            if(item.type == 1){
                return 'Medical notes have been provided.';
            }

            if(item.type == 2){
                let body = JSON.parse(item.body);
                let msg = '<b>' + body.length;
                if(body.length!=1){
                    msg+= ' people';
                }else{
                    msg += ' person'
                }
                msg += ' listed: </b>';
                for(let i in body){
                    msg += body[i].name + ', ';
                }
                // return body.length + ' pets listed.';
                return msg.substring(0, msg.length - 2) + '.';
            }

            if(item.type == 4){
                let body = JSON.parse(item.body);
                let msg = body.street + ' &bull; ' + body.city + ', ' + body.state;
                return msg;
            }

            if(item.type == 3){
                let body = JSON.parse(item.body);
                let msg = '<b>' + body.length;
                msg += ' pet';
                if(body.length!=1){
                    msg+= 's';
                }
                msg += ' listed: </b>';
                for(let i in body){
                    msg += body[i].name + ', ';
                }
                // return body.length + ' pets listed.';
                return msg.substring(0, msg.length - 2) + '.';
            }

            if(item.type == 5){
                let body = JSON.parse(item.body);
                let msg = '<b>' + body.length;
                msg += ' note';
                if(body.length!=1){
                    msg+= 's';
                }
                msg += '</b>';
                msg += (body.length == 1) ? ' has' : ' have';
                msg += ' been added.';
                return msg;
            }

            let body = item.body;

            // transform headings and p's to spaces
            body = body.replace(/<h1[^>]*>/g, '').replace(/<\/h1>/g, ' ');
            body = body.replace(/<h2[^>]*>/g, '').replace(/<\/h2>/g, ' ');
            body = body.replace(/<h3[^>]*>/g, '').replace(/<\/h3>/g, ' ');
            body = body.replace(/<p[^>]*>/g, '').replace(/<\/p>/g, ' ');

            // remove all other tags
            body = body.replace(/<\/?[^>]+(>|$)/g, "");

            if(body.length > 90){
                return body.substr(0,90) + '...';
            }

            return body;

        },
        getIcon(idx){
            if(idx == 1){
                return 'fa fa-notes-medical';
            }else if(idx == 2){
                return 'fa fa-users';
            }else if(idx == 3){
                return 'fa fa-dog';
            }else if(idx == 4){
                return 'fa fa-home';
            }

            return 'fa fa-comment-medical';
        },
        getType(idx){
            if(idx == 1){
                return 'Medical Notes';
            }else if(idx == 2){
                return 'Child and Family Care Notes';
            }else if(idx == 3){
                return 'Pet Care Notes';
            }else if(idx == 4){
                return 'Property Notes';
            }

            return 'Emergency Notes';
        },
        getInitText(idx){
            if(idx == 1){
                return 'Add any medication allergies or medical conditions you may have.';
            }else if(idx == 2){
                return 'List any actions needed for those left in your care.';
            }else if(idx == 3){
                return 'Provide instructions for feeding and caring for your pets.';
            }else if(idx == 4){
                return 'Add notes for accessing and caring for your home (plants, cars, etc.).';
            }

            return 'Add any other notes you wish to share with your emergecy contacts.';
        },
        handleDelete(){
            this.deleting = this.deleteItem.uuid;
            let request = {
                endpoint: '/notes/' + this.deleteItem.uuid, 
                method: 'post',
                data: {
                    '_method' : 'delete'
                },
                apiKey: this.user.api_token || null
            };
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 204){
                    this.$eventHub.$emit('snackBar', {
                        text: 'Your note has been removed.'
                    });
                }
            }).catch(()=>{
            }).finally(()=>{
                this.$eventHub.$emit('refreshUser');
                this.deleting = null;
                setTimeout(() => {
                    this.initData();
                }, 1000);
            });
        },
        handleAddItem(item){
            this.updateItem = item;
            this.$router.push('/app/notes/add');
        },
        handleUpdateItemRoute(){
            if(this.$route.params.id == 'add' && this.updateItem){
                return;
            }
            this.updateItem = null;
            if(this.$route.params.id){
                for(let i in this.user.notes){
                    if(this.user.notes[i].uuid == this.$route.params.id){
                        this.updateItem = this.user.notes[i];
                    }
                }
            }else{
                this.updateItem = null;
            }
        },
        handleCancel(){
            this.initData();
            this.$router.push('/app/notes');
        }
    },
    mounted(){
		this.initData();
        this.handleUpdateItemRoute();
	},
    watch: {
        updateItem(){
            this.$emit('updating', (this.updateItem) ? true : false);
        },
        '$route'(){
            this.handleUpdateItemRoute();
        }
    }
}
</script>