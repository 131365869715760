<template>
    <div>
        <v-card class="ma-0 mx-md-0 my-md-0" flat v-if="ready" :disabled="loading" :loading="loading">
            <v-card-text class="px-0">
                <template v-if="!adding && !updating">
                    <v-row class="mt-0" v-if="!content.length">
                        <v-col cols="12" class="pt-0">
                            <p class="text-center my-10">
                                <v-icon color="primary" x-large>fa fa-users</v-icon>
                                <br>
                                <v-btn color="primary" @click.native="handleAdd()" rounded class="mt-3">Add a Dependent</v-btn>
                            </p>
                        </v-col>
                    </v-row>
                    <v-row class="mt-0" v-else>
                        <v-col cols="12" class="pt-0">
                            <v-tabs v-model="view" centered slider-color="primary" height="50" slider-size="3" class="tabs-bold" color="primary" background-color="#eee" style="margin-top: -17px; border-top: 1px solid #ddd;">
                                <v-tab><v-icon small :color="(view==0) ? 'primary' : 'grey'" class="mr-2">fa fa-list</v-icon> Dependents</v-tab>
                                <v-tab><v-badge inline :color="(fileCount>0) ? 'primary' : 'grey'" :content="fileCount || '0'"><v-icon small :color="(view==1)? 'primary' : 'grey'" class="mr-1">fa fa-paperclip</v-icon>  Attachments</v-badge></v-tab>
                            </v-tabs>
                            <v-divider />
                            <v-alert v-if="view!==1 && (!fileCount || fileCount < 1)" dense type="info" class="body-2 mt-3 mx-2 mx-md-5 mb-0">
                                You can add photos and documents for those in your care via the "Attachments" tab above.
                            </v-alert>
                            <template v-if="view==0">
                                <v-list class="mx-2 pt-0 mt-2">
                                    <template v-for="contentItem, idx in content">
                                        <v-list-item :key="'pet' + idx" @click="handleUpdate(idx)">
                                            <v-list-item-avatar color="primary">
                                                <v-icon color="white">fa fa-user</v-icon>
                                            </v-list-item-avatar>
                                            <v-list-item-content>
                                                <v-list-item-title class="font-weight-medium">{{ contentItem.name }}</v-list-item-title>
                                                <v-list-item-subtitle>{{ contentItem.age }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                            <v-list-item-action>
                                                <v-icon color="primary">fa fa-edit</v-icon>
                                            </v-list-item-action>
                                        </v-list-item>
                                    </template>
                                </v-list>
                                <v-divider class="mb-3" />
                                <p class="text-center">
                                    <v-btn color="primary" @click.native="handleAdd()" rounded class="mt-3">
                                        <v-icon small color="white" class="mr-2">fa fa-plus-circle</v-icon>
                                        Add Another Dependent
                                    </v-btn>
                                </p>
                            </template>
                            <template v-else>
                                <Files :item="item" @fileCount="fileCount=$event" @loading="loading=$event" />
                            </template>
                        </v-col>
                    </v-row>
                </template>
                <template v-else>
                    <v-row>
                        <v-col cols="12" class="pt-0">
                            <template v-if="adding">
                                <h2 class="title px-6 mt-3 secondary--text">Add a Dependent:</h2>
                                <p class="subtitle-1 lh-12 secondary--text px-6 mb-2">
                                    Please be as detailed as possible so that your emergency contacts can clearly understand your important information and relay it to those providing care.
                                </p>
                                <p class="subtitle-1 lh-12 secondary--text px-6 mb-0">
                                    <b>Privacy Note: </b> This information is only viewable to your emergency contacts in the event you fail to check-in.
                                </p>
                                <v-divider class="mt-4" />
                            </template>
                            <template v-if="updating">
                                <p class="title grey lighten-3 pl-5 pt-3 pb-0 pb-md-0 mb-0 tedxt-center text-md-left secondary--text"  style="margin-top: -5px; border-top: 1px solid #ddd !important;">
                                    <span class="d-inline-block text-truncate" style="max-width: 80%;">{{ newItem.name }}</span>
                                    <v-btn v-if="updating" class="float-right" text color="error" @click.native="removing=true;"><v-icon>fa fa-trash-alt</v-icon></v-btn>
                                </p>
                                <v-divider />
                            </template>
                            <v-stepper v-model="step" vertical class="mx-0 pb-3" flat elevation="0">
                                <v-stepper-step :complete="step > 1" step="1" editable class="pb-2">
                                    <span class="subtitle-1 lh-14 font-weight-medium">Dependent Details</span>
                                    <small class="body-2" v-if="step>1">{{ newItem.name }} &bull; {{ newItem.age }}</small>
                                </v-stepper-step>
                                <v-stepper-content step="1" class="pl-0 pl-md-6">
                                    <v-row dense>
                                        <v-col cols="12">
                                            <span class="subtitle-1 text-label">Name of Dependent:</span>
                                            <v-text-field class="mt-1" dense :autofocus="!$vuetify.breakpoint.smAndDown" hide-details style="max-width: 400px;" outlined v-model="newItem.name" placeholder="Ex: Sarah (required)" />
                                            <v-icon color="primary" class="mr-1" x-small>fa fa-info-circle</v-icon><small class="caption">Full names not required.</small>
                                        </v-col>
                                        <v-col cols="12" class="py-2">
                                            <span class="subtitle-1 text-label">Dependent's Age:</span>
                                            <v-radio-group row v-model="newItem.age" hide-details class="my-0">
                                                <v-radio value="Infant" label="Infant" />
                                                <v-radio value="Toddler" label="Toddler" />
                                                <v-radio value="Child" label="Child" />
                                                <v-radio value="Adult" label="Adult" />
                                            </v-radio-group>
                                        </v-col>
                                    </v-row>
                                    <v-divider class="my-3" />
                                    <template v-if="!updating">
                                        <v-btn color="primary" class="mb-3" :disabled="!newItem.name || !newItem.age" @click.native="++step">Continue</v-btn>
                                    </template>
                                </v-stepper-content>

                                <v-stepper-step step="2" :complete="step>2  " :editable="newItem.name > ''" class="pb-2">
                                    <span class="subtitle-1 lh-14 font-weight-medium">Daily Care Instructions</span>
                                    <small class="body-2" v-if="step>2 || updating">Temp. Care Information Provided</small>
                                </v-stepper-step>
                                <v-stepper-content step="2" class="pl-0 pl-md-6 pb-3">
                                    <v-row dense>
                                        <v-col cols="12">
                                            <span class="subtitle-1 text-label">Who can provide temporary care for {{ newItem.name }}?</span>
                                            <v-textarea rows="2" class="mt-1" :autofocus="!$vuetify.breakpoint.smAndDown" auto-grow hide-details dense outlined v-model="newItem.tempCare" placeholder="List name(s) and contact information... (required)" />
                                            <v-icon color="primary" class="mr-1" x-small>fa fa-info-circle</v-icon><small @click="example='people-tempcare'" class="action-item caption primary--text">See Example</small>
                                        </v-col>
                                        <v-col cols="12">
                                            <span class="subtitle-1 text-label">What's the daily care routine for {{ newItem.name }}?</span>
                                            <v-textarea rows="2" class="mt-1" hide-details dense outlined auto-grow v-model="newItem.dailyCare" placeholder="Ex: As applies: feeding, changing... (optional)" />
                                            <v-icon color="primary" class="mr-1" x-small>fa fa-info-circle</v-icon><small @click="example='people-dailycare'" class="action-item caption primary--text">See Example</small>
                                        </v-col>
                                        <v-col cols="12" class="pt-2">
                                            <span class="subtitle-1 text-label">Does {{ newItem.name }} attend a nursery, school or other daily program?</span>
                                            <v-radio-group row v-model="newItem.hasSchool" hide-details class="my-0">
                                                <v-radio value="Yes" label="Yes" />
                                                <v-radio value="No" label="No" />
                                            </v-radio-group>
                                            <v-textarea v-if="newItem.hasSchool == 'Yes'" rows="2" class="mt-1" auto-grow hide-details dense outlined :autofocus="!$vuetify.breakpoint.smAndDown"  v-model="newItem.school" placeholder="List name(s), schedule and other important details..." />
                                            <v-icon color="primary" class="mr-1" x-small>fa fa-info-circle</v-icon><small @click="example='people-schooling'" class="action-item caption primary--text">See Example</small>
                                        </v-col>
                                    </v-row>
                                    <v-divider class="my-3" />
                                    <v-btn color="primary" v-if="!updating" class="mb-3" :disabled="!newItem.tempCare" @click.native="++step">Continue</v-btn>
                                </v-stepper-content>

                                <v-stepper-step step="3" :complete="step > 3" :editable="updating || step > 3" class="pb-2">
                                    <span class="subtitle-1 lh-14 font-weight-medium">Medical Information</span>
                                    <small class="body-2" v-if="updating || step > 3">{{ (newItem.hasMedications == 'Yes' || newItem.hasAllergies == 'Yes' || newItem.hasConditions == 'Yes' || (newItem.providers && newItem.providers.length)) ? 'Medical details provided.' : 'No medical details provided.' }}.</small>
                                </v-stepper-step>
                                <v-stepper-content step="3" class="pl-0 pl-md-6">
                                    <v-row dense>
                                        <v-col cols="12">
                                            <span class="subtitle-1 text-label mb-1 ">Who are the primary/other care providers for {{ newItem.name }}?</span>
                                            <v-textarea rows="2" hide-details dense outlined auto-grow :autofocus="!$vuetify.breakpoint.smAndDown" v-model="newItem.providers" placeholder="List names and contact information... (optional)" />
                                            <v-icon color="primary" class="mr-1" x-small>fa fa-info-circle</v-icon><small @click="example='people-careproviders'" class="action-item caption primary--text">See Example</small>
                                        </v-col>
                                        <v-col cols="12" class="pt-2">
                                            <span class="subtitle-1 text-label">Does {{ newItem.name }} take any medications?</span>
                                            <v-radio-group row v-model="newItem.hasMedications" hide-details class="my-0">
                                                <v-radio value="Yes" label="Yes" />
                                                <v-radio value="No" label="No" />
                                            </v-radio-group>
                                            <v-textarea v-if="newItem.hasMedications == 'Yes'" rows="2" auto-grow class="mt-1" hide-details dense outlined :autofocus="!$vuetify.breakpoint.smAndDown"  v-model="newItem.medications" placeholder="List any medications along with frequency and dosage..." />
                                            <v-icon color="primary" class="mr-1" x-small>fa fa-info-circle</v-icon><small @click="example='people-medications'" class="action-item caption primary--text">See Example</small>
                                        </v-col>
                                        <v-col cols="12" class="pt-2">
                                            <span class="subtitle-1 text-label">Does {{ newItem.name }} have any medical conditions?</span>
                                            <v-radio-group row v-model="newItem.hasConditions" hide-details class="my-0">
                                                <v-radio value="Yes" label="Yes" />
                                                <v-radio value="No" label="No" />
                                            </v-radio-group>
                                            <v-textarea v-if="newItem.hasConditions == 'Yes'" auto-grow rows="2" class="mt-1" hide-details dense outlined :autofocus="!$vuetify.breakpoint.smAndDown"  v-model="newItem.conditions" placeholder="Describe any medical conditions..." />
                                            <v-icon color="primary" class="mr-1" x-small>fa fa-info-circle</v-icon><small @click="example='people-conditions'" class="action-item caption primary--text">See Example</small>
                                        </v-col>
                                        <v-col cols="12" class="pt-2">
                                            <span class="subtitle-1 text-label">Does {{ newItem.name }} have any food, medicine or other allergies?</span>
                                            <v-radio-group row v-model="newItem.hasAllergies" hide-details class="my-0">
                                                <v-radio value="Yes" label="Yes" />
                                                <v-radio value="No" label="No" />
                                            </v-radio-group>
                                            <v-textarea v-if="newItem.hasAllergies == 'Yes'" auto-grow rows="2" class="mt-1" hide-details dense outlined :autofocus="!$vuetify.breakpoint.smAndDown"  v-model="newItem.allergies" placeholder="List and describe any known allergies..." />
                                            <v-icon color="primary" class="mr-1" x-small>fa fa-info-circle</v-icon><small @click="example='people-allergies'" class="action-item caption primary--text">See Example</small>
                                        </v-col>
                                    </v-row>
                                    <v-divider class="my-3 hidden-sm-and-down" v-if="!updating" />
                                    <v-btn color="primary" v-if="!updating" class="mb-3" @click.native="++step">Continue</v-btn>
                                </v-stepper-content>

                                <v-stepper-step step="4" :editable="updating" class="pb-2">
                                    <span class="subtitle-1 lh-14 font-weight-medium">Additional Information</span>
                                    <small class="body-2" v-if="updating">Additional information provided.</small>
                                </v-stepper-step>
                                <v-stepper-content step="4" class="pl-0 pl-md-6">
                                    <v-row dense>
                                        <v-col cols="12">
                                            <span class="subtitle-1 text-label mb-1 ">List any additional information you would like to provide for caring for {{ newItem.name }}:</span>
                                            <v-textarea rows="4" hide-details dense outlined auto-grow :autofocus="!$vuetify.breakpoint.smAndDown" v-model="newItem.additional" :placeholder="'Ex: ' + newItem.name + ' sometimes becomes anxious if... (optional)'" />
                                            <v-icon color="primary" class="mr-1" x-small>fa fa-info-circle</v-icon><small @click="example='people-extra'" class="action-item caption primary--text">See Example</small>
                                        </v-col>
                                    </v-row>
                                    <v-divider class="my-3 hidden-sm-and-down" v-if="!updating" />
                                    <v-btn color="primary" v-if="!updating" @click.native="save()">Save {{ newItem.name }}</v-btn>
                                </v-stepper-content>

                            </v-stepper>
                            
                        </v-col>
                    </v-row>
                </template>
            </v-card-text>
            <v-card-actions class="card-actions mt-1" id="form-actions">
                <v-spacer />
                <v-btn v-if="(adding || updating) && content.length" text @click.native="adding=false; updating=false;">Cancel</v-btn>
                <v-btn v-if="(adding || updating) && !content.length" text @click.native="$emit('cancel')">Cancel</v-btn>
                <v-btn v-if="(content.length && !adding && !updating) || !content.length" outlined text @click.native="$emit('cancel')">Back to Notes</v-btn>
                <v-btn v-if="updating" :disabled="!canSave" class="px-5" color="primary" @click.native="save()">Save</v-btn>
                <v-spacer />
            </v-card-actions>
        </v-card>
        <Confirm v-if="removing" title="Remove Dependent" :subtitle="'Removing ' + newItem.name + '...'" msg="Are you sure you want to remove this dependent?" @confirmed="handleDelete()" @closed="removing=false" />
        <NotesExamples v-if="example" :field="example" @closed="example=null" />
    </div>
</template>
<script>
    
import { mapState } from 'vuex'
import Confirm from '@/components/common/Confirm'
import Files from '@/components/user/notes/Files'
import NotesExamples from '@/components/common/NotesExamples'

export default {
    name: 'UserNotesUpdatePet',
    props: {
        item: {
            required: true,
            type: Object
        }
    },
    components: {
        Confirm,
        Files,
        NotesExamples
    },
    computed: {
        ...mapState(['user']),
        canSave(){
            return this.newItem.name.length > 0 && this.newItem.tempCare.length > 0;
        }
    },
    data: () => ({
        adding: false,
        example: null,
        fileCount: 0,
        loading: false,
        newItem: {
            age: 'Child',
            hasSchool: 'No',
            hasMedications: 'No',
            hasConditions: 'No',
            hasAllergies: 'No',
        },
        newItemCopy: {},
        content: [],
        errors: null,
        itemCopy: {},
        ready: false,
        removing: false,
        rules: {
            required: value => !!value || 'Required.',
            minlength: value => value && value.length > 5 || 'Must be at least 6 characters.',
        },
        step: 1,
        updating: false,
        updatingIdx: null,
        view: 0,
    }),
    methods: {
        initData(){
            this.itemCopy = JSON.parse(JSON.stringify(this.item));
            this.newItemCopy = JSON.parse(JSON.stringify(this.newItem));
            this.fileCount = this.item.files.length;
            if(this.itemCopy.body){
                this.content = JSON.parse(this.itemCopy.body);
            }
            this.ready = true;
        },
        handleAdd(){
            this.step=1;
            this.newItem = JSON.parse(JSON.stringify(this.newItemCopy));
            this.adding = true;
            this.updating = false;
        },
        handleUpdate(idx){
            this.step=1;
            this.updatingIdx = idx;
            this.newItem = this.content[idx];
            this.updating = true;
            this.adding = false;
        },
        handleDelete(){
            if(this.content.length > 1){
                this.content.splice(this.updatingIdx, 1);
            }else{
                // delete entire note
                this.handleDeleteNote();
                return;
            }
            this.updating = false;
            this.removing = false;
            this.save();
        },
        handleDeleteNote(){
            this.loading = true;
            let request = {
                endpoint: '/notes/' + this.item.uuid, 
                method: 'post',
                data: {
                    '_method' : 'delete'
                },
                apiKey: this.user.api_token || null
            };
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 204){
                    this.$eventHub.$emit('snackBar', {
                        text: 'Your notes have been removed.'
                    });
                    this.$eventHub.$emit('refreshUser');
                    this.$emit('cancel');
                }
            }).catch(()=>{
            }).finally(()=>{
                this.loading = false;
            });
        },
        save(){
            if(this.adding){
                this.content.push(this.newItem);
            }
            if(this.updating){
                this.content[this.updatingIdx] = this.newItem;
            }
            this.$emit('saved', this.content);
            this.updating = false;
            this.updatingIdx = null;
            this.adding = false;
        },
        getIcon(item){
            
            if(item.type == 'Dog'){
                return 'fa fa-dog';
            }
            
            if(item.type == 'Cat'){
                return 'fa fa-cat';
            }

            return 'fa fa-feather';
        }
    },
    beforeMount(){
        this.$eventHub.$on('footerInt', (visible) => {
            this.showAppBar = !visible;
        });
    },
    beforeDestroy(){
        
    },
    mounted(){
		this.initData();
	},
    watch: {
    }
}
</script>