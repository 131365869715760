<template>
    <div>
        <v-card class="ma-0 mx-md-0 my-md-0" flat v-if="ready" :disabled="loading" :loading="loading">
            <v-card-text class="px-0">
                <template v-if="!adding && !updating">
                    <v-row class="mt-0" v-if="!content.length">
                        <v-col cols="12" class="pt-0">
                            <p class="text-center my-10">
                                <v-icon color="primary" x-large>{{ getIcon(item.type) }}</v-icon>
                                <br>
                                <span class="mt-3 title d-block secondary--text">{{ getType(item.type) }}</span>
                                <v-btn color="primary" outlined @click.native="handleAdd()" rounded class="mt-3">Add Your First Note</v-btn>
                            </p>
                        </v-col>
                    </v-row>
                    <v-row class="mt-0" v-else>
                        <v-col cols="12" class="pt-0">
                            <v-tabs v-model="view" centered slider-color="primary" height="50" slider-size="3" class="tabs-bold" color="primary" background-color="#eee" style="margin-top: -17px; border-top: 1px solid #ddd;">
                                <v-tab><v-icon small :color="(view==0) ? 'primary' : 'grey'" class="mr-2">fa fa-list</v-icon> Your Notes</v-tab>
                                <v-tab><v-badge inline :color="(fileCount>0) ? 'primary' : 'grey'" :content="fileCount || '0'"><v-icon small :color="(view==1)? 'primary' : 'grey'" class="mr-1">fa fa-paperclip</v-icon>  Attachments</v-badge></v-tab>
                            </v-tabs>
                            <v-divider />
                            <v-alert outlined v-if="view!==1 && (!fileCount || fileCount < 5)" dense type="info" class="body-2 mt-3 mx-2 mx-md-5 mb-0">
                                You can add photos and documents for your notes via the "Attachments" tab above.
                            </v-alert>
                            <template v-if="view==0">
                                <v-list class="mx-2 mt-2">
                                    <template v-for="contentItem, idx in content">
                                        <v-list-item :key="'note' + idx" @click="handleUpdate(idx)">
                                            <v-list-item-avatar color="primary">
                                                <v-icon color="white">fa fa-comment-medical</v-icon>
                                            </v-list-item-avatar>
                                            <v-list-item-content>
                                                <v-list-item-title class="font-weight-medium">{{ contentItem.title }}</v-list-item-title>
                                                <v-list-item-subtitle>{{ contentItem.content }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                            <v-list-item-action>
                                                <v-icon color="primary">fa fa-edit</v-icon>
                                            </v-list-item-action>
                                        </v-list-item>
                                    </template>
                                </v-list>
                                <v-divider class="mb-3" />
                                <p class="text-center">
                                    <v-btn color="primary" @click.native="handleAdd()" rounded class="mt-3">
                                        <v-icon small color="white" class="mr-2">fa fa-plus-circle</v-icon>
                                        Add Another Note
                                    </v-btn>
                                </p>
                            </template>
                            <template v-else>
                                <Files :item="item" :key="filesKey" @updated="handleFileUpdate" @fileCount="fileCount=$event" @loading="loading=$event" />
                            </template>
                        </v-col>
                    </v-row>
                </template>
                <template v-else>
                    <v-row>
                        <v-col cols="12" class="pt-0">
                            <template v-if="adding">
                                <h2 class="title px-6 mt-3 secondary--text">Add a note:</h2>
                                <p class="subtitle-1 lh-12 secondary--text px-6 mb-0">
                                    Please be as detailed as possible so that your emergency contacts can clearly understand your important information.
                                </p>
                                <v-divider class="mt-4" />
                            </template>
                            <template v-if="updating">
                                <p class="title grey lighten-3 pl-5 pt-3 pb-0 pb-md-0 mb-0 tedxt-center text-md-left secondary--text"  style="margin-top: -5px; border-top: 1px solid #ddd !important;">
                                    <span class="d-inline-block text-truncate" style="max-width: 80%;">Update Your Note</span>
                                    <v-btn v-if="updating" class="float-right" text color="error" @click.native="removing=true;"><v-icon>fa fa-trash-alt</v-icon></v-btn>
                                </p>
                                <v-divider />
                            </template>
                            
                            <v-row dense class="ml-5 mt-3">
                                <v-col cols="12">
                                    <span class="subtitle-1 text-label">Note Title:</span>
                                    <v-text-field class="mt-1" dense :autofocus="!$vuetify.breakpoint.smAndDown" hide-details style="max-width: 400px;" outlined v-model="newItem.title" placeholder="Ex: Contacting my Parents (required)" />
                                    <v-icon color="primary" class="mr-1" x-small>fa fa-info-circle</v-icon><small @click="example='other-title'" class="action-item caption primary--text">See Examples</small>
                                </v-col>
                                <v-col cols="12" class="py-2">
                                    <span class="subtitle-1 text-label">Note Contents:</span>
                                    <v-textarea class="mt-1" rows="5" hide-details dense outlined v-model="newItem.content" placeholder="Ex: Please contact my mom at... (required)" />
                                    <v-icon color="primary" class="mr-1" x-small>fa fa-info-circle</v-icon><small @click="example='other-content'" class="action-item caption primary--text">See Examples</small>
                                </v-col>
                                <v-col cols="12" class="py-2">
                                    <v-btn color="primary" v-if="!updating" :disabled="!newItem.title.length || !newItem.content.length" @click.native="save()">Save Note</v-btn>
                                </v-col>
                            </v-row>

                        </v-col>
                    </v-row>
                </template>
            </v-card-text>
            <v-card-actions v-if="(adding || updating)" class="card-actions mt-1" id="form-actions">
                <v-spacer />
                <v-btn v-if="(adding || updating) && content.length" text @click.native="adding=false; updating=false;">Cancel</v-btn>
                <v-btn v-if="(adding || updating) && !content.length" outlined text @click.native="$emit('cancel')">Cancel</v-btn>
                <v-btn v-if="updating" :disabled="!canSave" class="px-5" color="primary" @click.native="save()">Save</v-btn>
                <v-spacer />
            </v-card-actions>
        </v-card>
        <Confirm v-if="removing" title="Remove Note" subtitle="Removing Emergency Note..." msg="Are you sure you want to remove this note?" @confirmed="handleDelete()" @closed="removing=false" />
        <NotesExamples v-if="example" :field="example" @closed="example=null" />
    </div>
</template>
<script>
    
import { mapState } from 'vuex'
import Confirm from '@/components/common/Confirm'
import Files from '@/components/user/notes/Files'
import NotesExamples from '@/components/common/NotesExamples'

export default {
    name: 'UserNotesUpdateDefault',
    props: {
        item: {
            required: true,
            type: Object
        }
    },
    components: {
        Confirm,
        Files,
        NotesExamples
    },
    computed: {
        ...mapState(['user']),
        canSave(){
            return this.newItem.title.length > 0 && this.newItem.content.length > 0;
        }
    },
    data: () => ({
        adding: false,
        example: null,
        fileCount: 0,
        filesKey: 0,
        loading: false,
        newItem: {
            title: '',
            content: ''
        },
        newItemCopy: {},
        content: [],
        errors: null,
        itemCopy: {},
        ready: false,
        removing: false,
        rules: {
            required: value => !!value || 'Required.',
            minlength: value => value && value.length > 5 || 'Must be at least 6 characters.',
        },
        step: 1,
        updating: false,
        updatingIdx: null,
        view: 0,
    }),
    methods: {
        initData(){
            ++this.filesKey;
            this.itemCopy = JSON.parse(JSON.stringify(this.item));
            this.newItemCopy = JSON.parse(JSON.stringify(this.newItem));
            this.fileCount = this.item.files.length;
            if(this.itemCopy.body){
                this.content = JSON.parse(this.itemCopy.body);
            }else{
                this.handleAdd();
            }
            this.ready = true;
        },
        getIcon(idx){
            if(idx == 1){
                return 'fa fa-comment-medical';
            }else if(idx == 2){
                return 'fa fa-users';
            }else if(idx == 3){
                return 'fa fa-dog';
            }else if(idx == 4){
                return 'fa fa-home';
            }

            return 'fa fa-comment-medical';
        },
        getType(idx){
            if(idx == 1){
                return 'Medical Notes';
            }else if(idx == 2){
                return 'Child/Family Care Notes';
            }else if(idx == 3){
                return 'Pet Care Notes';
            }else if(idx == 4){
                return 'Housing Notes';
            }

            return 'Other Emergency Notes';
        },
        handleAdd(){
            this.step=1;
            this.newItem = JSON.parse(JSON.stringify(this.newItemCopy));
            this.adding = true;
            this.updating = false;
        },
        handleUpdate(idx){
            this.step=1;
            this.updatingIdx = idx;
            this.newItem = this.content[idx];
            this.updating = true;
            this.adding = false;
        },
        handleFileUpdate(){
            this.$eventHub.$emit('refreshUser');
            this.$emit('updated');
        },
        handleDelete(){
            if(this.content.length > 1){
                this.content.splice(this.updatingIdx, 1);
            }else{
                this.content = [];
            }
            this.updating = false;
            this.removing = false;
            this.save();
        },
        save(){
            if(!this.newItem.uuid){
                this.newItem.uuid = Math.random().toString(36).slice(-6);
            }
            if(this.adding){
                this.content.push(this.newItem);
            }
            if(this.updating){
                this.content[this.updatingIdx] = this.newItem;
            }
            this.$emit('saved', this.content);
            this.updating = false;
            this.updatingIdx = null;
            this.adding = false;
        }
    },
    beforeMount(){
        this.$eventHub.$on('footerInt', (visible) => {
            this.showAppBar = !visible;
        });
    },
    beforeDestroy(){
        
    },
    mounted(){
		this.initData();
	},
    watch: {
    }
}
</script>