<template>
    <v-card :class="(!items.length) ? 'mb-3' : 'mt-3'">
        <v-card-text class="info-card">
            <v-row dense>
                <v-col cols="12">
                    <p class="text-center secondary--text title mt-3 mt-lg-1 mb-0">
                        <v-icon color="primary" class="mb-md-2" x-large>fa fa-notes-medical</v-icon>
                        <br>
                        Emergency Notes
                    </p>
                </v-col>
                <v-col cols="12" md="10" lg="10" offset-lg="1" offset-md="1">
                    <p class="secondary--text lh-13 text-center subtitle-1 mb-1">
                        Your emergency notes provide critical information to your emergency contacts should something happen to you, such as child and pet care, medical information and other details that may assist others in providing aid to you in your time of need.
                    </p>
                    <v-divider class="my-3" />
                        <p class="secondary--text subtitle-1 lh-13 text-center text-md-center mb-1">
                            <v-icon color="primary" small class="mr-1">fa fa-lock</v-icon> Your notes are only visible to your emergency contacts if you fail to check in.
                        </p>
                        <p class="secondary--text subtitle-1 lh-13 text-center text-md-center mb-1">
                            <v-icon color="primary" small class="mr-1">fa fa-lock</v-icon> Your notes are encrypted to protect your private information.
                        </p>
                    </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>

export default {
    name: 'UserContactsInfo',
    props: {
        items: {
            required: true,
            type: Array
        }
    },
    computed: {
    },
    data: () => ({
    }),
    methods: {
    },
    mounted(){
	},
    watch: {
    }
}
</script>