<template>
    <div id="notes">
        <v-toolbar class="sub-bar">
            <v-toolbar-title>
                <v-icon>fa fa-notes-medical</v-icon> Your Emergency Notes
            </v-toolbar-title>
            <v-btn absolute right small rounded color="primary" class="mt-05 hidden" @click.native="view='add'" :disabled="view!='list' || isUpdating">
                <v-icon small class="mr-2" color="white">fa fa-plus</v-icon> 
                <span class="hidden-sm-and-down">Add a Note</span>
                <span class="hidden-md-and-up">Create</span>
            </v-btn>
        </v-toolbar>
        <v-row class="sub-cont" :no-gutters="$vuetify.breakpoint.smAndDown">
            <v-col cols="3" class="hidden-sm-and-down pr-0">
                <LeftNav />
            </v-col>
            <v-col cols="12" md="9" v-if="view=='add'">
                <Add @created="view='list'" @cancel="view='list'" />
            </v-col>
            <v-col cols="12" md="9" v-if="view=='list'">
                <List @updating="handleUpdating($event)" :items="user.notes" @add="view='add'" />
            </v-col>
        </v-row>
    </div>
</template>
<script>
    
import { mapState } from 'vuex'
import Add from '@/components/user/notes/Add'
import List from '@/components/user/notes/List'
import LeftNav from '@/components/ui/LeftNav';

export default {
    name: 'UserNotes',
    components: {
        Add,
        List,
        LeftNav
    },
    computed: {
        ...mapState(['user']),
    },
    data: () => ({
        isUpdating: false,
        view: 'list',
    }),
    methods: {
        initData(){
            this.$appFn.setTitle("Emergency Notes - I'm Okay", true);
        },
        handleUpdating(updating){
            this.isUpdating=updating;
            setTimeout(() => {
                document.body.scrollTop=0;
            }, 20);
        }
    },
    mounted(){
		this.initData();
	},
    watch: {
    }
}
</script>