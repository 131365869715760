<template>
    <v-card class="elevation-4">
        <v-card-title class="card-header page">
            <v-icon color="primary" class="mr-1">fa fa-plus-circle</v-icon> Add an Emergency Note
        </v-card-title>
        <v-card-text class="">
            <v-alert class="mt-2" dense type="error" v-if="errors">
                {{ errors }}
            </v-alert>
            <v-row class="mt-2">
                <v-col cols="12" md="6" lg="5" offset-lg="1" class="pb-0">
                    <v-select
                        :autofocus="!$vuetify.breakpoint.smAndDown" 
                        outlined
                        v-model="item.type"
                        :items="types"
                        label="Note Type"
                        placeholder="Ex: Family Care, Pet Care, Property Information..."
                        :rules="[rules.required]"
                        validate-on-blur
                        :success="item.type !== null"
                        class="mb-4"
                        hide-details
                    />
                    <v-text-field
                        outlined
                        v-model="item.title"
                        label="Note Title"
                        :success="item.title.length > 1"
                        placeholder="Ex: Pet Feeding Needs, Aparment Info, etc."
                        :rules="[rules.required]"
                        validate-on-blur
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="5" class="pt-0 pb-0 pt-md-3">
                    <Photo :item="item" @selected="photo=$event" @removed="handlePhotoRemoved()" />
                </v-col>
                <v-col cols="12" md="12" lg="10" offset-lg="1" class="pt-0">
                    <v-textarea
                        v-model="item.body"
                        outlined
                        label="Note Contents"
                        :success="item.body.length > 1"
                        placeholder="Ex: Details related to this note..."
                        :rules="[rules.required]"
                        class="mt-0"
                        validate-on-blur
                        hide-details
                        filled
                    />
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions class="card-actions mt-1">
            <v-spacer />
            <v-btn color="white" text @click.native="$emit('cancel')">Cancel</v-btn>
            <v-btn color="primary" :loading="saving" dark :disabled="!canSubmit" class="px-5 ml-5" @click.native="save()">Save Note</v-btn>
            <v-spacer />
        </v-card-actions>
    </v-card>
</template>
<script>
    
import { mapState } from 'vuex'
import Photo from '@/components/common/Photo'

export default {
    name: 'UserNotessAdd',
    components: {
        Photo
    },
    computed: {
        ...mapState(['user']),
        canSubmit(){
            return this.item.type && this.item.title.length && this.item.body.length;
        }
    },
    data: () => ({
        errors: null,
        formData: null,
        item: {
            title: '',
            body: '',
            type: null,
            image: null
        },
        types: [
            { value: 1, text: 'Medical Alert'},
            { value: 3, text: 'Child/Family Care'},
            { value: 2, text: 'Pet Care'},
            { value: 4, text: 'Property Information'},
            { value: 5, text: 'Vehicle Information'},
            { value: 99, text: 'Other...'},
        ],
        photo: null,
        rules: {
            required: value => !!value || 'Required.',
            minlength: value => value && value.length > 5 || 'Must be at least 6 characters.',
        },
        saving: false,
    }),
    methods: {
        initData(){
            this.formData = new FormData();
        },
        save(){ 
            this.saving = true;
            this.errors = null;
            this.formData.append('type', this.item.type);
            this.formData.append('title', this.item.title);
            this.formData.append('body', this.item.body);
            let request = {
                endpoint: '/notes', 
                method: 'post',
                data: this.formData,
                apiKey: this.user.api_token || null,
                header: { 'Content-Type': 'multipart/form-data' },
            };
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 201){
                    this.$eventHub.$emit('refreshUser');
                    this.$eventHub.$emit('snackBar', {
                        text: 'Your note has been added.'
                    });
                    this.$emit('created');
                }else{
                    this.handleErrorResponse(res);
                }
            }).catch(()=>{
                this.errors = "Something went wrong! Please try again.";
            }).finally(()=>{
                this.saving = false;
                this.$eventHub.$emit('refreshUser');
            });
        },
        handleErrorResponse(res){
            this.errors = '';
            for(let i in res.data.errors){
                this.errors += res.data.errors[i];
            }
        },
        setFormImage(){
            fetch(this.photo)
            .then((response) => {
                return response.blob();
            }).then((blob) => {
                this.formData.append('image', blob);
                // this.uploadPhoto(true);
            });
        },
        handlePhotoRemoved(){
            this.photo = null;
            this.formData.delete('image');
        },
    },
    mounted(){
		this.initData();
	},
    watch: {
        photo(){
            if(this.photo){
                this.setFormImage();
            }
        }
    }
}
</script>